import React, { useEffect, useState } from "react";
import Navbar from "../../components/layouts/Navbar";
import Footer from "../../components/layouts/Footer";
import { useDispatch, useSelector } from "react-redux";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import axios from "axios";
import toast from "react-hot-toast";
import ProtectedRoute from "../../components/layouts/ProtectedRoute";
import { setLoading } from "../../redux/reducers/userSlice";
import Select from "react-tailwindcss-select";

const Contact = () => {
  const loading = useSelector((state) => state.user?.loading);
  const userProfile = useSelector((state) => state.user?.data);
  const [amount, setAmount] = useState(null);
  const dispatch = useDispatch();

  const [bank, setBank] = useState(null);

  const banks = [
    { value: "Halkbank", label: "Halkbank" },
    { value: "VakifBank", label: "VakifBank" },
    { value: "Ziraat Bank", label: "Ziraat Bank" },
    { value: "Akbank", label: "Akbank" },
    { value: "Anadolubank", label: "Anadolubank" },
    { value: "Fibabanka", label: "Fibabanka" },
    { value: "Şekerbank", label: "Şekerbank" },
    { value: "Turkish Bank", label: "Turkish Bank" },
    { value: "Türk Ticaret Bankası", label: "Türk Ticaret Bankası" },
    { value: "Türkiye İş Bankası", label: "Türkiye İş Bankası" },
    { value: "Yapı Kredi", label: "Yapı Kredi" },
    { value: "Alternatif Bank", label: "Alternatif Bank" },
    { value: "Burgan Bank", label: "Burgan Bank" },
    { value: "DenizBank", label: "DenizBank" },
    { value: "Garanti BBVA", label: "Garanti BBVA" },
    { value: "HSBC", label: "HSBC" },
    { value: "ING", label: "ING" },
    { value: "Odeabank", label: "Odeabank" },
    { value: "QNB Finansbank", label: "QNB Finansbank" },
    { value: "TEB", label: "TEB" },
    {
      value: "Türkiye Emlak Katılım Bankası",
      label: "Türkiye Emlak Katılım Bankası",
    },
    { value: "Vakıf Katılım Bank", label: "Vakıf Katılım Bank" },
    { value: "Ziraat Katılım", label: "Ziraat Katılım" },
    { value: "Albaraka Türk", label: "Albaraka Türk" },
    { value: "Kuveyt Türk", label: "Kuveyt Türk" },
    {
      value: "Türkiye Finans Katılım Bankası",
      label: "Türkiye Finans Katılım Bankası",
    },
  ];
  console.log(userProfile);
  const onSendEmail = async (e) => {
    e.preventDefault();

    dispatch(setLoading(true));
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/transaction/withdraw/${userProfile?.user?._id}/${amount}`,
        {
          email: userProfile?.user?.email,
          iban_number: userProfile?.payment?.iban_number,
          first_name: userProfile?.payment?.first_name,
          last_name: userProfile?.payment?.last_name,
          bank_name: userProfile?.payment?.bank_name,
          payment_system: "IBAN",
        }
      );
      if (res.status === 200 || res.status === 201) {
        toast.success("İstek başarıyla gönderildi");
      } else {
        toast.error("Bir şeyler ters gitti, daha sonra tekrar deneyin");
      }
    } catch (e) {
      console.log(e);
      toast.error("Bir şeyler ters gitti, daha sonra tekrar deneyin");
    }
    dispatch(setLoading(false));
    setAmount("");
  };

  return (
    <ProtectedRoute>
      <Navbar />
      <div className="max-w-[1400px] my-28 mx-4 lg:md:mx-auto grid lg:md:md:grid-cols-2 grid-cols-1 gap-x-6 items-center">
        <div className="lg:md:md:block hidden">
          <Player
            autoplay
            loop
            src="https://lottie.host/97ed9d4e-3c50-40ca-8248-0da7d8b9bb96/s9rDi8rqTs.json"
            className="lg:md:md:h-[800px] h-[50vh]"
          >
            <Controls
              visible={false}
              buttons={["play", "repeat", "frame", "debug"]}
            />
          </Player>
        </div>
        <div className="w-full">
          <div className="border rounded-3xl w-full my-6 lg:md:md:p-6 p-2">
            <div className="flex items-center justify-center flex-col">
              <p className="break-words lg:md:md:text-3xl text-2xl font-medium text-center">
                Para Çekme
              </p>
              <p className="break-words text-secondary font-medium my-2 text-center">
                Toplam Bakiye : ₺{userProfile?.balance?.toFixed(2) ?? 0}
              </p>
            </div>
            <form
              onSubmit={onSendEmail}
              className="lg:md:p-8 p-4 rounded-2xl bg-white"
            >
              <div className="flex flex-col">
                <div className="flex flex-col space-y-2 my-4">
                  <div className="flex items-center justify-between gap-2 w-full">
                    <div className="w-1/2">
                      <label htmlFor="first_name" className="font-medium">
                        Ad
                      </label>
                      <input
                        type="text"
                        id="first_name"
                        name="first_name"
                        className="w-full p-3 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                        placeholder=" Ad"
                        value={
                          userProfile ? userProfile?.payment?.first_name : ""
                        }
                        disabled={userProfile ? true : false}
                        required
                      />
                    </div>
                    <div className="w-1/2">
                      <label htmlFor="last_name" className="font-medium">
                        Soyad
                      </label>
                      <input
                        type="text"
                        id="last_name"
                        name="last_name"
                        className="w-full p-3 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                        placeholder=" Soyad"
                        value={
                          userProfile ? userProfile?.payment?.last_name : ""
                        }
                        disabled={userProfile ? true : false}
                        required
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-3 gap-2 w-full">
                    <label
                      htmlFor="phone"
                      className="col-span-full font-medium"
                    >
                      Telefon Numarası
                    </label>
                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      className="col-span-full p-3 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                      placeholder=" Telefon Numarası"
                      value={
                        userProfile
                          ? userProfile?.user?.phone_number
                          : "Lütfen profilinizden Telefon Numaranızı ekleyin"
                      }
                      disabled={userProfile ? true : false}
                      required
                    />
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-2 w-full">
                    <label
                      htmlFor="email"
                      className="col-span-full font-medium"
                    >
                      E-mail Adresi
                    </label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      className="col-span-full p-3 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                      placeholder="E-mail Adresi"
                      value={
                        userProfile
                          ? userProfile?.user?.email
                          : "Lütfen profilinizden e-posta adresinizi ekleyin"
                      }
                      disabled={userProfile ? true : false}
                      required
                    />
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-2 w-full">
                    <label htmlFor="iban" className="col-span-full font-medium">
                      IBAN Numarası
                    </label>
                    <input
                      type="text"
                      id="iban"
                      name="iban"
                      className="col-span-full p-3 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                      placeholder="IBAN numaranız"
                      value={
                        userProfile
                          ? userProfile?.payment?.iban_number
                          : "Lütfen profilden IBAN numaranız ekleyin"
                      }
                      disabled={userProfile ? true : false}
                      required
                    />
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-2 w-full">
                    <label htmlFor="bank" className="col-span-full font-medium">
                      Banka Adı
                    </label>
                    <input
                      type="text"
                      id="iban"
                      name="iban"
                      className="col-span-full p-3 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                      placeholder="Banka Seçiniz"
                      value={
                        userProfile
                          ? userProfile?.payment?.bank_name
                          : "Lütfen profilinizden bankanızı ekleyin"
                      }
                      disabled={userProfile ? true : false}
                      required
                    />
                  </div>
                  {/* <div className="w-full">
                    <label htmlFor="bank" className="col-span-full font-medium">
                      Banka Adı
                    </label>
                    <Select
                      isDisabled
                      value={userProfile?.payment?.bank_name}
                      onChange={(e) => setBank(e)}
                      options={banks}
                      isSearchable
                      loading={loading}
                      primaryColor={"lime"}
                      classNames={{
                        menuButton: ({ isDisabled }) =>
                          `flex rounded-lg text-black border border-gray-300 px-3 py-[2px] shadow-sm transition-all duration-300 focus:outline-none !w-full ${
                            isDisabled
                              ? "bg-gray-100"
                              : "bg-white hover:border-gray-400 focus:border-primary focus:ring focus:ring-primary/10"
                          }`,
                        menu: "absolute z-10 w-full bg-white shadow-lg border rounded p-2 mt-1.5 rounded-lg text-gray-700",
                        searchIcon:
                          "absolute top-2 w-5 h-5  pb-0.5 ml-2 text-gray-500",
                        searchBox:
                          "border w-full rounded  !placeholder-white pl-8 outline-none focus:outline-none",
                        listItem: ({ isSelected }) =>
                          `block transition duration-200 p-2 rounded-lg cursor-pointer select-none truncate rounded ${
                            isSelected
                              ? `text-white bg-primary`
                              : `text-black hover:bg-green-100 hover:text-primary`
                          }`,
                      }}
                      style={{ padding: ".5rem" }}
                      placeholder="Banka Seçiniz"
                    />
                  </div> */}
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-2 w-full">
                    <label
                      htmlFor="amount"
                      className="col-span-full font-medium"
                    >
                      Tutar*
                    </label>
                    <input
                      type="number"
                      id="amount"
                      name="amount"
                      className="col-span-full p-3 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                      placeholder="Çekmek istediğiniz tutarı giriniz"
                      onChange={(e) => setAmount(parseFloat(e.target.value))}
                      value={amount}
                      required
                    />
                  </div>
                </div>
              </div>
              {!userProfile?.payment?.first_name && (
                <p className="text-red-400 mb-2">
                  Devam etmek için profil sayfasından ödeme ayrıntılarınızı
                  ekleyin
                </p>
              )}
              <div className="flex items-center justify-between space-x-2 pt-3 border-t">
                <button
                  type="submit"
                  className={`px-4 font-medium text-white bg-primary border border-transparent rounded-md shadow-sm hover:bg-opacity-90 transition-all focus:outline-none w-full ${
                    loading ? "cursor-not-allowed py-3 opacity-50" : "py-3"
                  }`}
                  disabled={
                    loading || amount < 1 || !userProfile?.payment?.first_name
                  }
                >
                  {loading ? (
                    <div className="loaderProfile mx-auto "></div>
                  ) : (
                    "Onayla"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </ProtectedRoute>
  );
};

export default Contact;
