import Navbar from "../../components/layouts/Navbar";
import Footer from "../../components/layouts/Footer";

const Help = () => {
  return (
    <div>
      <Navbar />

      <div className="border rounded-3xl  mb-14 w-[95vw] max-w-[1400px]   mx-auto mt-28 ">
        <div className="w-full min-h-[60vh] flex flex-col items-center ">
          {" "}
          <p className="break-words my-6 text-3xl font-medium  mx-auto max-w-[1400px] ">
            Yardım & Destek
          </p>
          <div className="join join-vertical lg:md:w-2/3 w-full my-1 border shadow p-3">
            <p className="my-3 font-semibold  text-lg"> Kayıt & Giriş</p>
            <hr className="my-2" />
            <div className="collapse collapse-arrow join-item bg-green-100 border border-base-300 my-2">
              <input type="radio" name="my-accordion-4" defaultChecked />
              <div className="collapse-title text-lg lg:text-xl font-medium">
                KocFreelancing'e nasıl kayıt olurum?
              </div>
              <div className="collapse-content leading-relaxed">
                <p>
                  Sağ üst bölümde bulunan ''Kayıt'' butonuna tıklayıp, açılan
                  ekranda istenilen bilgileri eksiksiz ve doğru bir şekilde
                  girip, en son aşamada e-mailinize gelen doğrulama linkine
                  tıklayarak kayıt işleminizi gerçekleştirebilirsiniz.
                </p>
              </div>
            </div>

            <div className="collapse collapse-arrow join-item bg-red-100 border border-base-300 my-2">
              <input type="radio" name="my-accordion-4" defaultChecked />
              <div className="collapse-title text-lg lg:text-xl font-medium">
                Doğrulama maili gelmedi, ne yapabilirim?
              </div>
              <div className="collapse-content leading-relaxed">
                <p>
                  {" "}
                  <a
                    href="mailto:destek@kocfreelancing.com"
                    target="_blank"
                    className="underline text-blue-500 "
                  >
                    destek@kocfreelancing.com
                  </a>{" "}
                  email adresimiz üzerinden, sorununuzu çözmemiz için bize
                  bildirebilirsiniz.
                </p>
              </div>
            </div>
            <div className="collapse collapse-arrow join-item bg-yellow-100 border border-base-300 my-2">
              <input type="radio" name="my-accordion-4" defaultChecked />
              <div className="collapse-title text-lg lg:text-xl font-medium">
                KocFreelancing'e nasıl giriş yapabilirim?
              </div>
              <div className="collapse-content leading-relaxed">
                <p>
                  Sağ üst bölümde bulunan 'Giriş' sekmesine tıklayarak giriş
                  yapabilirsiniz.
                </p>
              </div>
            </div>
            <div className="collapse collapse-arrow join-item bg-blue-100 border border-base-300 my-2">
              <input type="radio" name="my-accordion-4" defaultChecked />
              <div className="collapse-title text-lg lg:text-xl font-medium">
                Şifremi unuttum, nasıl bir yol izleyebilirim?
              </div>
              <div className="collapse-content leading-relaxed">
                <p>
                  Giriş Yap ekranının altında bulunan ''şifrenizi mi
                  unuttunuz?'' yazısının üzerine tıklayarak, mail adresinize
                  sıfırlama bağlantısı gönderebilirsiniz.
                </p>
              </div>
            </div>
          </div>
          <div className="join join-vertical lg:md:w-2/3 w-full my-1 border shadow p-3 ">
            <p className="my-3 font-semibold  text-lg">Profil</p>{" "}
            <hr className="my-2" />
            <div className="collapse collapse-arrow join-item bg-green-100 border border-base-300 my-2">
              <input type="radio" name="my-accordion-4" defaultChecked />
              <div className="collapse-title text-lg lg:text-xl font-medium">
                Profil bilgilerimi nasıl ve nereden düzenleyebilirim?
              </div>
              <div className="collapse-content leading-relaxed">
                <p>
                  Hesabınıza giriş yaptıktan sonra, sağ üst bölümde bulunan
                  profil fotoğrafınızın üzerine tıklayarak profilinize gidin.
                  Ardından, eklemek istediğiniz bilgileri "+" işaretine,
                  düzenlemek istediğiniz bilgileri "kalem" simgesine, silmek
                  istediğiniz bilgileri ise "çöp kutusu" simgesine tıklayarak
                  düzenleyebilirsiniz.
                </p>
              </div>
            </div>
            <div className="collapse collapse-arrow join-item bg-red-100 border border-base-300 my-2">
              <input type="radio" name="my-accordion-4" defaultChecked />
              <div className="collapse-title text-lg lg:text-xl font-medium">
                Telefon numaramı ya da e-mail adresimi nereden düzenleyebilirim?
              </div>
              <div className="collapse-content leading-relaxed">
                <p>
                  Hesabınızın çalınmasını ve ele geçirilmesini önlemek amacıyla,
                  bu işlemleri sadece{" "}
                  <a
                    href="mailto:destek@kocfreelancing.com"
                    target="_blank"
                    className="underline text-blue-500 "
                  >
                    destek@kocfreelancing.com
                  </a>{" "}
                  email adresimize mail atarak gerçekleştirebilirsiniz.
                </p>
              </div>
            </div>
          </div>
          <div className="join join-vertical lg:md:w-2/3 w-full my-1 border shadow p-3">
            <p className="my-3 font-semibold  text-lg">
              {" "}
              <hr className="my-2" />
              İş İlanı Yayınlama
            </p>
            <div className="collapse collapse-arrow join-item bg-green-100 border border-base-300 my-2">
              <input type="radio" name="my-accordion-4" defaultChecked />
              <div className="collapse-title text-lg lg:text-xl font-medium">
                Projeme uygun bir serbest çalışan arıyorum. İş ilanını nereden
                ve nasıl yayınlarım
              </div>
              <div className="collapse-content leading-relaxed">
                <p>
                  İş ilanı yayınlamak için KocFreelancing hesabınıza giriş
                  yaptıktan sonra, üst menüde bulunan "İş İlanları" sekmesine
                  tıklayın ve ardından "Yeni Bir İş İlanı Ver" butonuna
                  tıklayın. Buradan, projeniz ile ilgili gerekli bilgileri
                  doldurduktan sonra "Yayınla" butonuna tıklayarak iş ilanınızı
                  yayınlayabilirsiniz.
                </p>
              </div>
            </div>

            <div className="collapse collapse-arrow join-item bg-red-100 border border-base-300 my-2">
              <input type="radio" name="my-accordion-4" defaultChecked />
              <div className="collapse-title text-lg lg:text-xl font-medium">
                İş başlığı nedir?
              </div>
              <div className="collapse-content leading-relaxed">
                <p>
                  İş ilanında veya proje tanımında işin veya projenin genel
                  adını ifade eder.
                </p>
              </div>
            </div>
            <div className="collapse collapse-arrow join-item bg-yellow-100 border border-base-300 my-2">
              <input type="radio" name="my-accordion-4" defaultChecked />
              <div className="collapse-title text-lg lg:text-xl font-medium">
                Alt kategori nedir?
              </div>
              <div className="collapse-content leading-relaxed">
                <p>
                  KocFreelancing'de meslekler kategorilere ayrılmıştır. Örneğin;
                  boya & badana, temizlik, organizasyon gibi. Evinizi boyatmak
                  istiyorsanız "Boya & Badana" kategorisinden "Ev Boyama" alt
                  kategorisini seçmeniz gerekmektedir. Ofisinizi temizletmek
                  istiyorsanız ise "Temizlik" kategorisinden "İş Yeri Temizliği"
                  alt kategorisini seçmeniz gerekmektedir. Bu sayede doğrudan
                  hedeflediğiniz serbest çalışana ulaşabilirsiniz.
                </p>
              </div>
            </div>
            <div className="collapse collapse-arrow join-item bg-blue-100 border border-base-300 my-2">
              <input type="radio" name="my-accordion-4" defaultChecked />
              <div className="collapse-title text-lg lg:text-xl font-medium">
                İş ilanı vermek ücretli mi?
              </div>
              <div className="collapse-content leading-relaxed">
                <p>
                  KocFreelancing, iş ilanı yayınlarken hiçbir şekilde ücret
                  talep etmez.
                </p>
              </div>
            </div>
          </div>
          <div className="join join-vertical lg:md:w-2/3 w-full my-1 border shadow p-3">
            <p className="my-3 font-semibold  text-lg">
              {" "}
              <hr className="my-2" />
              İlana Teklif Verme
            </p>
            <div className="collapse collapse-arrow join-item bg-green-100 border border-base-300 my-2">
              <input type="radio" name="my-accordion-4" defaultChecked />
              <div className="collapse-title text-lg lg:text-xl font-medium">
                iş ilanlarını nereden görebilirim?
              </div>
              <div className="collapse-content leading-relaxed">
                <p>
                  İş ilanlarını görmek için KocFreelancing hesabınıza giriş
                  yapın. Üst menüde bulunan "İş İlanları" sekmesinden ilanları
                  görüntüleyebilirsiniz.
                </p>
              </div>
            </div>

            <div className="collapse collapse-arrow join-item bg-red-100 border border-base-300 my-2">
              <input type="radio" name="my-accordion-4" defaultChecked />
              <div className="collapse-title text-lg lg:text-xl font-medium">
                iş ilanlarına nasıl teklif verebilirim?
              </div>
              <div className="collapse-content leading-relaxed">
                <p>
                  Yeteneğinize uygun bir iş ilanını bulduktan sonra, ilanın
                  üzerine tıklayın. Teklif edeceğiniz ücreti, teslimat süresini
                  ve projeye uygunluğunuzu açıklayın. Ardından "Gönder" butonuna
                  tıklayarak teklifinizi yayınlayın.
                </p>
              </div>
            </div>
            <div className="collapse collapse-arrow join-item bg-yellow-100 border border-base-300 my-2">
              <input type="radio" name="my-accordion-4" defaultChecked />
              <div className="collapse-title text-lg lg:text-xl font-medium">
                Teklif ücreti nedir?
              </div>
              <div className="collapse-content leading-relaxed">
                <p>
                  Teklif ettiğiniz ücretin %10'u kadar bakiyenizden kesinti
                  yapılır. Teklif verdiğiniz işi alamadığınızda ise kesilen
                  tutar tekrar bakiyenize yatırılır. [ Bu konuyla ilgili daha
                  detaylı bilgi ve şartlar için kullanıcı sözleşmesine göz
                  atınız. ]
                </p>
              </div>
            </div>
          </div>
          <div className="join join-vertical lg:md:w-2/3 w-full my-1 border shadow p-3">
            <p className="my-3 font-semibold  text-lg">
              {" "}
              <hr className="my-2" />
              Tekrar İşe Al
            </p>
            <div className="collapse collapse-arrow join-item bg-green-100 border border-base-300 my-2">
              <input type="radio" name="my-accordion-4" defaultChecked />
              <div className="collapse-title text-lg lg:text-xl font-medium">
                Daha önce çalıştığım serbest çalışanı tekrar nasıl bulup
                projemde çalışmasını teklif edebilirim?
              </div>
              <div className="collapse-content leading-relaxed">
                <p>
                  Önceki işe aldığınız çalışanları görmek için hesabınıza giriş
                  yaptıktan sonra üst menüde bulunan "Çalışanlarım" sekmesine
                  tıklayıp "Durum" bölümünden "Tamamlandı" butonunu
                  aktifleştirerek önceden çalıştığınız serbest çalışanları
                  görebilirsiniz. Serbest çalışana iş daveti göndermek için
                  "Tekrar İşe Al" butonuna tıklayıp, projenizle ilgili istenen
                  bilgileri yazınız. Proje davetiniz serbest çalışana ulaşacak
                  ve projenize bir teklif yapacaktır. Teklifi kabul ettiğinizde,
                  projenizde çalışmaya başlayacaktır.
                </p>
              </div>
            </div>
          </div>
          <div className="join join-vertical lg:md:w-2/3 w-full my-1 border shadow p-3">
            <p className="my-3 font-semibold  text-lg">Para Çekme</p>{" "}
            <hr className="my-2" />
            <div className="collapse collapse-arrow join-item bg-green-100 border border-base-300 my-2">
              <input type="radio" name="my-accordion-4" defaultChecked />
              <div className="collapse-title text-lg lg:text-xl font-medium">
                Hesabımda bulunan bakiyeyi nasıl çekebilirim?
              </div>
              <div className="collapse-content leading-relaxed">
                <p>
                  Hesabınıza giriş yaparak sağ üst kısımda bulunan profil
                  fotoğrafınızın üzerine tıklayınız. Açılan menüde 'Para Çekme'
                  sekmesine tıklayınız ve açılan ekranda gerekli bilgileri
                  doldurunuz, ardından 'Onayla' butonuna tıklayarak bakiyenizi
                  banka hesabınıza aktarabilirsiniz
                </p>
              </div>
            </div>
            <div className="collapse collapse-arrow join-item bg-red-100 border border-base-300 my-2">
              <input type="radio" name="my-accordion-4" defaultChecked />
              <div className="collapse-title text-lg lg:text-xl font-medium">
                IBAN numarası nereden eklenir ve nereden güncellenir?
              </div>
              <div className="collapse-content leading-relaxed">
                <p>
                  Hesabınıza giriş yaptıktan sonra profil bölümünüzde bulunan
                  'Ödeme Bilgileri' kısmından banka adını ve IBAN numarasını
                  ekleyebilir veya güncelleyebilirsiniz.
                </p>
              </div>
            </div>
            <div className="collapse collapse-arrow join-item bg-yellow-100 border border-base-300 my-2">
              <input type="radio" name="my-accordion-4" defaultChecked />
              <div className="collapse-title text-lg lg:text-xl font-medium">
                Para çekme talebimin durumunu nereden takip edebilirim?
              </div>
              <div className="collapse-content leading-relaxed">
                <p>
                  KocFreelancing hesabınıza giriş yaparak sağ üst kısımda
                  bulunan profil fotoğrafınıza tıklayın. Açılan menüde 'İşlem
                  Geçmişi' butonuna tıklayarak para çekme talebinizin durumunu
                  takip edebilirsiniz.
                </p>
              </div>
            </div>
            <div className="collapse collapse-arrow join-item bg-blue-100 border border-base-300 my-2">
              <input type="radio" name="my-accordion-4" defaultChecked />
              <div className="collapse-title text-lg lg:text-xl font-medium">
                Para çekme talebinde '' Reddedildi '' yazıyor, ne yapacağım?
              </div>
              <div className="collapse-content leading-relaxed">
                <p>
                  KocFreelancing ilk para çekme taleplerinde veya şüpheli
                  gördüğü işlemlerde dolandırıcılığı önlemek adına para çekme
                  talebini reddetme hakkına sahiptir. Para çekme talebi
                  reddedildiği zaman açıklama kısmında reddedilme nedenini
                  belirtir ve kimlik doğrulaması ister. Kimlik doğrulaması
                  başarıyla tamamladığınız zaman bakiyenizi çekebilirsiniz.
                </p>
              </div>
            </div>
            <div className="collapse collapse-arrow join-item bg-green-100 border border-base-300 my-2">
              <input type="radio" name="my-accordion-4" defaultChecked />
              <div className="collapse-title text-lg lg:text-xl font-medium">
                Para çekme talebinde '' Onaylandı '' yazıyor, ne kadar sürede
                hesabıma geçer?
              </div>
              <div className="collapse-content leading-relaxed">
                <p>
                  Bu durum müşterisi olduğunuz bankanıza bağlı olarak değişiklik
                  gösterebilir, bankanızdan detaylı bilgiye ulaşabilirsiniz.
                </p>
              </div>
            </div>
            <div className="collapse collapse-arrow join-item bg-red-100 border border-base-300 my-2">
              <input type="radio" name="my-accordion-4" defaultChecked />
              <div className="collapse-title text-lg lg:text-xl font-medium">
                Para çekme ile ilgili herhangi bir sorunda ne yapmam gerekir?
              </div>
              <div className="collapse-content leading-relaxed">
                <p>
                  Para çekme talebi öncesinde soracağınız sorular için:{" "}
                  <a
                    href="mailto:destek@kocfreelancing.com"
                    target="_blank"
                    className="underline text-blue-500 "
                  >
                    destek@kocfreelancing.com
                  </a>{" "}
                  email adresine mail atabilirsiniz, Para çekme talebi
                  sonrasında herhangi bir sorun yaşadıysanız:{" "}
                  <a
                    href="mailto:muhasebe@kocfreelancing.com"
                    target="_blank"
                    className="underline text-blue-500 "
                  >
                    muhasebe@kocfreelancing.com
                  </a>{" "}
                  e-mail adresine mail atabilirsiniz.
                </p>
              </div>
            </div>
          </div>
          <div className="join join-vertical lg:md:w-2/3 w-full my-1 border shadow p-3">
            <p className="my-3 font-semibold  text-lg">Para Yatırma</p>{" "}
            <hr className="my-2" />
            <div className="collapse collapse-arrow join-item bg-yellow-100 border border-base-300 my-2">
              <input type="radio" name="my-accordion-4" defaultChecked />
              <div className="collapse-title text-lg lg:text-xl font-medium">
                Hesabım nasıl bakiye ekleyebilirim?
              </div>
              <div className="collapse-content leading-relaxed">
                <p>
                  KocFreelancing hesabınıza giriş yaparak sağ üst kısımda
                  bulunan profil fotoğrafınıza tıklayın. Açılan menüde 'Bakiye
                  Ekle' sekmesine tıklayın. Açılan ekranda ödeme yönteminizi
                  seçerek bakiye ekleyebilirsiniz.
                </p>
              </div>
            </div>
            <div className="collapse collapse-arrow join-item bg-blue-100 border border-base-300 my-2">
              <input type="radio" name="my-accordion-4" defaultChecked />
              <div className="collapse-title text-lg lg:text-xl font-medium">
                IBAN ile bakiye nasıl eklenir?
              </div>
              <div className="collapse-content leading-relaxed">
                <p>
                  Müşterisi olduğunuz bankanın mobil uygulamasından ya da
                  ATM'lerden KocFreelancing'in 'IBAN İle Öde' bölümünde
                  belirtmiş olduğu banka bilgilerini yazarak ve açıklama kısmına
                  KocFreelancing'e üye olduğunuz e-mail adresinizi eksiksiz ve
                  doğru bir şekilde yazarak EFT / Havale / FAST işlemini
                  gerçekleştirebilirsiniz. Transfer işlemi gerçekleştikten sonra
                  'IBAN İle Öde' bölümünde bulunan, işlemi gerçekleştirdiğiniz
                  bankanın adı ve hesabımıza transfer ettiğiniz tutarı yazarak
                  'Onayla' butonuna tıklayınız.
                </p>
              </div>
            </div>
            <div className="collapse collapse-arrow join-item bg-green-100 border border-base-300 my-2">
              <input type="radio" name="my-accordion-4" defaultChecked />
              <div className="collapse-title text-lg lg:text-xl font-medium">
                IBAN ile bakiye ekledim, hesabıma ne zaman geçer?
              </div>
              <div className="collapse-content leading-relaxed">
                <p>
                  Hesaplar arası transfer süresi, müşterisi olduğunuz bankaya
                  bağlı olarak değişiklik gösterebilir. Bankanızdan detaylı
                  bilgiye ulaşabilirsiniz.
                </p>
              </div>
            </div>
            <div className="collapse collapse-arrow join-item bg-red-100 border border-base-300 my-2">
              <input type="radio" name="my-accordion-4" defaultChecked />
              <div className="collapse-title text-lg lg:text-xl font-medium">
                Para çekme ile ilgili herhangi bir sorunda ne yapmam gerekir?
              </div>
              <div className="collapse-content leading-relaxed">
                <p>
                  Para yatırma talebi öncesinde soracağınız sorular için:{" "}
                  <a
                    href="mailto:destek@kocfreelancing.com"
                    target="_blank"
                    className="underline text-blue-500 "
                  >
                    destek@kocfreelancing.com
                  </a>{" "}
                  email adresine mail atabilirsiniz, <br />
                  Para yatırma talebi sonrasında herhangi bir sorun
                  yaşadıysanız:{" "}
                  <a
                    href="mailto:muhasebe@kocfreelancing.com"
                    target="_blank"
                    className="underline text-blue-500 "
                  >
                    muhasebe@kocfreelancing.com
                  </a>{" "}
                  e-mail adresine mail atabilirsiniz.
                </p>
              </div>
            </div>
          </div>
          <div className="join join-vertical lg:md:w-2/3 w-full my-1 border shadow p-3">
            <p className="my-3 font-semibold  text-lg">Hesap Silme</p>{" "}
            <hr className="my-2" />
            <div className="collapse collapse-arrow join-item bg-green-100 border border-base-300 my-2">
              <input type="radio" name="my-accordion-4" defaultChecked />
              <div className="collapse-title text-lg lg:text-xl font-medium">
                Hesabımı silmek istiyorum, nasıl silerim?
              </div>
              <div className="collapse-content leading-relaxed">
                <p>
                  {" "}
                  <a
                    href="mailto:destek@kocfreelancing.com"
                    target="_blank"
                    className="underline text-blue-500 "
                  >
                    destek@kocfreelancing.com
                  </a>{" "}
                  email adresine hesabınızı silmek istediğinizi
                  belirtebilirsiniz, gerekli incelemelerden sonra hesabınız
                  silinecektir. [ Bu konuyla ilgili daha detaylı bilgi ve
                  şartlar için kullanıcı sözleşmesine göz atınız. ]
                </p>
              </div>
            </div>
          </div>
          <div className="join join-vertical lg:md:w-2/3 w-full my-1 border shadow p-3">
            <p className="my-3 font-semibold  text-lg">Diğer</p>{" "}
            <hr className="my-2" />
            <div className="collapse collapse-arrow join-item bg-green-100 border border-base-300 my-2">
              <input type="radio" name="my-accordion-4" defaultChecked />
              <div className="collapse-title text-lg lg:text-xl font-medium">
                Bunların dışında sorum var
              </div>
              <div className="collapse-content leading-relaxed">
                <p>
                  Yardım & Destek bölümünden sorularınızı cevaplayamadıysak ve
                  sorunlarınızı çözemediysek üzgünüz.
                  <br />
                  <br />
                  Sorularınızı cevaplamak ve sorunlarınızı hızlı bir şekilde
                  çözmek ya da hataları düzeltmemiz için lütfen bizimle{" "}
                  <a
                    href="mailto:destek@kocfreelancing.com"
                    target="_blank"
                    className="underline text-blue-500 "
                  >
                    destek@kocfreelancing.com
                  </a>{" "}
                  email adresi üzerinden iletişime geçiniz.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Help;
