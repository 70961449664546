import Footer from "../../components/layouts/Footer";
import Navbar from "../../components/layouts/Navbar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SlArrowLeftCircle } from "react-icons/sl";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { FaHandHoldingDollar, FaStar } from "react-icons/fa6";
import Cookies from "js-cookie";
import {
  MdAccessTime,
  MdBookmarkAdded,
  MdDelete,
  MdEdit,
  MdJoinInner,
  MdOutlineLocationOn,
  MdOutlinePermIdentity,
  MdOutlineReviews,
} from "react-icons/md";
import { VscUnverified } from "react-icons/vsc";
import { Rating, StickerStar } from "@smastrom/react-rating";
import { useForm } from "react-hook-form";
import {
  getJobById,
  addBookmark,
  addProposal,
  deleteBookmark,
  deleteProposal,
  acceptProposal,
  deleteJob,
  cancelJob,
  getProfile,
} from "../../axios/axios";
import { FaRegBookmark, FaRegUserCircle } from "react-icons/fa";
import { setLoading } from "../../redux/reducers/loadingSlice";
import { format, formatDistance } from "date-fns";
import { tr } from "date-fns/locale";
import { GrMapLocation } from "react-icons/gr";
import Description from "../../components/ui/Description";
import { AnimatePresence } from "framer-motion";
import DeleteModal from "../../components/modals/DeleteModal";
import ProposalModal from "../../components/modals/ProposalModal";
import JobsModal from "../../components/modals/JobsModal";
import { Link } from "react-router-dom";
import ProtectedRoute from "../../components/layouts/ProtectedRoute";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import { RxCross1 } from "react-icons/rx";
import Modal from "../../components/ui/Modal";
import toast from "react-hot-toast";
import axios from "axios";

const Job = () => {
  // Next.js router
  const navigate = useNavigate();
  const email = Cookies.get("email");

  const { id } = useParams();
  // Component state
  const userProfile = useSelector((state) => state.user.data);
  const dispatch = useDispatch();
  const jobData = useSelector((state) => state.jobs.jobData?.data);
  const isLoading = useSelector((state) => state.loading.loading);
  const [date, setDate] = useState(null);
  const [sort, setSort] = useState("Newest");
  const [showConfirmationModal, setShowConfirmationModal] = useState(null);
  const [charge, setCharge] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoading(true));
      try {
        await dispatch(
          getJobById({ userId: userProfile?.user?._id, jobId: id, sort: sort })
        );
      } catch (error) {
        console.error("Error fetching job data:", error);
      }

      setDate(new Date(jobData?.profile?.createdAt));
      dispatch(setLoading(false));
    };

    fetchData();
  }, [dispatch, id, jobData?.profile?.createdAt, sort, userProfile]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    const commission = (charge * 10) / 100;
    if (userProfile?.balance < commission) {
      toast.error(
        "Hesabınızda yeterli bakiye olmadığı için teklifi gönderemezsiniz."
      );
      return;
    }
    if (
      userProfile?.description &&
      userProfile?.hourly_rate &&
      userProfile?.sub_title
    ) {
      dispatch(setLoading(true));
      dispatch(
        addProposal({
          dynamicParams: { userId: userProfile?.user?._id },
          bodyData: { ...data, offer: id, created_by: userProfile?.user?._id },
        })
      )
        .then(() => {
          dispatch(getJobById({ userId: userProfile?.user?._id, jobId: id }));
        })
        .then(() => {
          dispatch(setLoading(false));
          reset();
        })
        .catch((error) => {
          console.error("Error:", error);
          dispatch(setLoading(false));
        });
    } else {
      toast.error("Lütfen önce profilinizi tamamlayın");
      navigate("/profile/me");
    }
  };

  const handleAddBookmark = (id) => {
    dispatch(setLoading(true));
    dispatch(
      addBookmark({
        dynamicParams: {},
        bodyData: {
          user: userProfile?.user?._id,
          offer: id,
        },
      })
    )
      .then(() => {
        // After adding experience, fetch the updated profile data
        return dispatch(
          getJobById({ userId: userProfile?.user?._id, jobId: id })
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    dispatch(setLoading(false));
  };
  const handleDeleteBookmark = (id) => {
    dispatch(setLoading(true));
    dispatch(
      deleteBookmark({
        dynamicParams: { bookmarkId: id },
        bodyData: null,
      })
    )
      .then(() => {
        // After adding experience, fetch the updated profile data
        return dispatch(
          getJobById({ userId: userProfile?.user?._id, jobId: jobData?._id })
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    dispatch(setLoading(false));
  };
  const [showDeleteProposalModal, setShowDeleteProposalModal] = useState(null);
  const [showEditProposalModal, setShowEditProposalModal] = useState(null);
  const [showCancelJobModal, setShowCancelJobModal] = useState(null);
  const handleCancelJob = () => {
    dispatch(setLoading(true));
    dispatch(
      cancelJob({
        dynamicParams: { jobId: showCancelJobModal },
        bodyData: null,
      })
    )
      .then(() => {
        // After adding Proposal, fetch the updated profile data
        dispatch(getJobById({ userId: userProfile?.user?._id, jobId: id }));
      })
      .then(() => {
        // Once profile is fetched, reset loading state and close modal
        setShowCancelJobModal(null);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    dispatch(setLoading(false));
  };

  const handleAcceptProposal = (proposalId) => {
    dispatch(setLoading(true));
    dispatch(
      acceptProposal({
        dynamicParams: { proposalId: proposalId, jobId: id },
        bodyData: {},
      })
    )
      .then(() => {
        // After adding Proposal, fetch the updated profile data
        dispatch(getJobById({ userId: userProfile?.user?._id, jobId: id }));
        navigate("/myhires");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    dispatch(setLoading(false));
  };
  const handleDeleteProposal = () => {
    dispatch(setLoading(true));
    dispatch(
      deleteProposal({
        dynamicParams: { proposalId: showDeleteProposalModal },
        bodyData: null,
      })
    )
      .then(() => {
        // After adding Proposal, fetch the updated profile data
        return dispatch(
          getJobById({ userId: userProfile?.user?._id, jobId: id })
        );
      })
      .then(() => {
        // Once profile is fetched, reset loading state and close modal
        setShowDeleteProposalModal(null);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    dispatch(setLoading(false));
  };
  const [proposalData, setProposalData] = useState({
    bid_amount: 0,
    delivery_time: 0,
    proposal_description: "",
  });

  const [displayedRemainingReviews, setDisplayedRemainingReviews] =
    useState(10);

  const loadMoreReviews = () => {
    setDisplayedRemainingReviews((prev) => prev + 10);
  };

  const showLessReviews = () => {
    setDisplayedRemainingReviews(10);
  };

  const [showEditJobModal, setShowEditJobModal] = useState(null);
  const [showDeleteExperienceModal, setShowDeleteExperienceModal] =
    useState(null);

  const handleDeleteExperience = () => {
    dispatch(setLoading(true));
    if (jobData?.status === "open") {
      dispatch(
        deleteJob({
          dynamicParams: { jobId: showDeleteExperienceModal },
          bodyData: null,
        })
      )
        .then(() => {
          // After adding experience, fetch the updated profile data
          navigate("/jobs");
        })
        .then(() => {
          // Once profile is fetched, reset loading state and close modal
          setShowDeleteExperienceModal(null);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      dispatch(
        cancelJob({
          dynamicParams: { jobId: showDeleteExperienceModal },
          bodyData: null,
        })
      )
        .then(() => {
          // After adding experience, fetch the updated profile data
          navigate("/jobs");
        })
        .then(() => {
          // Once profile is fetched, reset loading state and close modal
          setShowDeleteExperienceModal(null);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    dispatch(setLoading(false));
  };

  const sendMessageToFreelancer = async (freelancerEmail) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/offer/initiateChat/${userProfile?.user?._id}`,
        { freelancerEmail }
      );

      if (res.status === 200) {
        console.log(res.data);
        navigate(`/messages`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ProtectedRoute>
      <Navbar />
      {isLoading ? (
        <div className=" max-w-[1400px]  lg:md:px-4     my-28 mx-2 lg:mx-auto lg:grid grid-cols-4 gap-x-6">
          <div className="border rounded-3xl max-w-[1400px]  lg:md:px-4    p-6  mb-14 mx-auto col-span-3 w-full flex items-center justify-center h-[120vh]">
            <div className="loader"></div>
          </div>{" "}
          <div className="col-span-1">
            <div className=" w-full border rounded-3xl h-[30vh]  bg-[#fff] overflow-hidden py-3 px-6">
              <p className="break-words text-lg  my-6 font-semibold text-secondary">
                About The Client
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className=" max-w-[1400px]  lg:md:px-4    my-28 mx-2 lg:mx-auto lg:grid grid-cols-4 gap-x-6">
          <div className="  mb-14 mx-auto col-span-3 w-full">
            <div className="border rounded-3xl max-w-[1400px]  lg:md:px-4     justify-center  p-6  w-full">
              <div className="flex items-center gap-x-2">
                <SlArrowLeftCircle
                  className="text-primary text-xl cursor-pointer transition-all hover:text-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                />{" "}
                <p className=" text-sm text-secondary">
                  {" "}
                  {jobData?.createdAt &&
                    (({ timestamp }) => (
                      <span>
                        {formatDistance(new Date(timestamp), new Date(), {
                          addSuffix: true,
                          locale: tr,
                        })}
                      </span>
                    ))({ timestamp: jobData?.createdAt })}{" "}
                  yayınlandı
                </p>
              </div>

              {jobData?.user !== userProfile?.user?._id ? (
                <div className="space-y-2  my-2">
                  <p className="break-all  font-medium text-lg lg:text-2xl ">
                    {jobData?.title}
                  </p>{" "}
                  <div className="flex items-center gap-x-2 flex-wrap gap-y-2">
                    <button className="rounded-3xl  px-4 py-1 text-sm bg-primary bg-opacity-[0.18] text-secondary text-center active:scale-95 m-[3px]">
                      {jobData?.status === "open"
                        ? "Açık"
                        : jobData?.status === "inprogress"
                        ? "Devam etmekte "
                        : jobData?.status === "completed"
                        ? "Tamamlandı"
                        : jobData?.status === "canceled"
                        ? "İptal edildi"
                        : jobData?.status === "invited"
                        ? "Davet edildi"
                        : jobData?.status}
                    </button>
                    <button className="rounded-3xl  px-4 py-1 whitespace-nowrap text-sm bg-primary bg-opacity-[0.18] text-secondary text-center active:scale-95 ">
                      {jobData?.subCategory?.name}
                    </button>
                    <button className="rounded-3xl  px-4 py-1 whitespace-nowrap text-sm bg-primary bg-opacity-[0.18] text-secondary text-center active:scale-95 ">
                      {jobData?.category?.name}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="lg:flex space-y-2 lg:space-y-0 lg:items-centerf space-x-1 lg:space-x-0 my-2 lg:justify-between">
                  {" "}
                  <div className="lg:flex items-start space-x-2 space-y-2 lg:space-y-0">
                    <div>
                      <p className="break-all mb-2 font-medium text-lg lg:text-2xl ">
                        {jobData?.title}
                      </p>{" "}
                      <div className="flex items-center justify-start flex-wrap gap-2">
                        <button className="rounded-3xl  whitespace-nowrap  px-4 py-1 text-sm bg-primary bg-opacity-[0.18] text-secondary text-center active:scale-95 ">
                          {jobData?.status === "open"
                            ? "Açık"
                            : jobData?.status === "inprogress"
                            ? "Devam etmekte "
                            : jobData?.status === "completed"
                            ? "Tamamlandı"
                            : jobData?.status === "canceled"
                            ? "İptal edildi"
                            : jobData?.status === "invited"
                            ? "Davet edildi"
                            : jobData?.status}
                        </button>{" "}
                        <button className="rounded-3xl px-4 py-1  whitespace-nowrap text-sm bg-primary bg-opacity-[0.18] text-secondary text-center active:scale-95 ">
                          {jobData?.subCategory?.name}
                        </button>
                        <button className="rounded-3xl whitespace-nowrap  px-4 py-1 text-sm bg-primary bg-opacity-[0.18] text-secondary text-center active:scale-95 ">
                          {jobData?.category?.name}
                        </button>
                      </div>
                    </div>
                  </div>{" "}
                  {jobData?.status === "open" && (
                    <div className="flex items-start gap-x-2">
                      <div
                        className="rounded-full p-[6px] border-[1px] border-primary hover:bg-gray-50 cursor-pointer transition-all"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent event from bubbling up
                          setShowEditJobModal(jobData?._id);
                        }}
                      >
                        <MdEdit className="text-primary text-lg" />
                      </div>
                      <div
                        className="rounded-full p-[6px] border-[1px] border-primary hover:bg-gray-50 cursor-pointer transition-all"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent event from bubbling up
                          setShowDeleteExperienceModal(jobData?._id);
                        }}
                      >
                        <MdDelete className="text-primary text-lg" />
                      </div>
                    </div>
                  )}
                </div>
              )}
              <p className="break-words py-3 text-green-900">
                {jobData?.job_description}
              </p>
              <div className="flex justify-between items-center"></div>
              <div className="lg:flex items-center  ">
                <p className="break-all  lg:py-3 text-secondary ">
                  Ort. bütçe :{" "}
                  <span className="font-medium">₺{jobData?.budget}</span> |
                </p>

                <p className="break-all  lg:py-3 text-secondary lg:ml-1">
                  Ort. süre :{" "}
                  <span className="font-medium">{jobData?.deadline} gün</span> |
                </p>
                <p className="break-all  lg:py-3 text-secondary lg:ml-1">
                  Proje boyutu :{" "}
                  <span className="font-medium">{jobData?.project_size} </span>
                </p>
              </div>
              <p className="break-words text-md py-3 font-medium text-secondary">
                İstenen yetenekler :
              </p>
              <div className="flex flex-wrap gap-x-2  gap-y-2 mb-3">
                {jobData?.skills?.map((element, idx) => (
                  <div key={`skillset-${idx}`}>
                    <button className="rounded-3xl px-3 py-1  bg-gray-400 bg-opacity-[0.18] text-secondary text-center active:scale-95 ">
                      {element?.name}
                    </button>
                  </div>
                ))}
              </div>
              <p className="break-words text-md  text-secondary">
                Teklifler :{" "}
                <span className="font-medium">
                  {jobData?.proposals?.filter((proposal) => {
                    // Filter out deleted proposals
                    return proposal?.deleted === false;
                  }).length ?? 0}
                </span>
              </p>
              <p
                className="break-words text-sm text-secondary tooltip cursor-pointer hover:bg-gray-50 pr-2 py-2"
                onClick={(e) => {
                  navigator.clipboard.writeText(jobData?._id);
                  const tooltip = e.target;
                  tooltip.innerText = "Kopyalandı";
                  setTimeout(
                    () => (tooltip.innerText = `#${jobData?._id}`),
                    2000
                  );
                }}
              >
                İş kimliği :{" "}
                <span className="font-medium">#{jobData?._id}</span>
              </p>

              {jobData?.user !== userProfile?.user?._id &&
                (jobData?.status === "open" ||
                (jobData?.status === "invited" &&
                  jobData?.hired === userProfile?.user?._id) ? (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="border-t py-6 my-6">
                      <p className="break-words text-2xl font-medium mb-6">
                        Bir teklif yap
                      </p>
                      {/* Conditional rendering based on proposals */}
                      {jobData?.proposals?.some(
                        (proposal) =>
                          proposal?.created_by?._id ===
                            userProfile?.user?._id && !proposal?.deleted
                      ) ? (
                        <p>Bu iş için zaten teklif verdiniz.</p>
                      ) : (
                        <>
                          <div>
                            <div className="space-y-2">
                              <div className="">
                                <label
                                  htmlFor="bid_amount"
                                  className="font-medium text-sm"
                                >
                                  Teklif Tutarı
                                </label>
                                <input
                                  type="number"
                                  id="bid_amount"
                                  {...register("bid_amount", {
                                    required: "Bu alan gereklidir",
                                    min: {
                                      value: 1,
                                      message:
                                        "Teklif tutarı 1₺'den fazla olmalıdır",
                                    },
                                  })}
                                  className="w-full rounded-md border mt-1 border-gray-300 shadow-sm px-3 py-2 focus:outline-none focus:ring-primary focus:border-primary"
                                  placeholder="örn. 500"
                                  onChange={(e) => {
                                    setCharge(parseFloat(e.target.value) || 0);
                                  }}
                                />
                                {errors.bid_amount && (
                                  <span className="text-red-500">
                                    {errors.bid_amount.message}
                                  </span>
                                )}
                              </div>
                              <div className="">
                                <label
                                  htmlFor="delivery_time"
                                  className="font-medium text-sm"
                                >
                                  Teslimat Süresi (gün)
                                </label>
                                <input
                                  type="number"
                                  id="delivery_time"
                                  {...register("delivery_time", {
                                    required: "Bu alan gereklidir",
                                    min: {
                                      value: 1,
                                      message:
                                        "Teslimat Süresi 1 günden fazla olmalıdır",
                                    },
                                  })}
                                  className="w-full rounded-md border mt-1 border-gray-300 shadow-sm px-3 py-2 focus:outline-none focus:ring-primary focus:border-primary"
                                  placeholder="örn. 5"
                                />
                                {errors.delivery_time && (
                                  <span className="text-red-500">
                                    {errors.delivery_time.message}
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className="mt-2">
                              <label
                                htmlFor="proposal_description"
                                className="font-medium text-sm"
                              >
                                Teklif Açıklaması
                              </label>
                              <textarea
                                id="proposal_description"
                                rows={6}
                                {...register("proposal_description", {
                                  required: true,
                                })}
                                className="w-full rounded-md border mt-1 border-gray-300 shadow-sm px-3 py-2 focus:outline-none focus:ring-primary focus:border-primary"
                                placeholder="Sizi bu proje için en iyi aday yapan şey nedir?"
                              />
                              {errors.proposal_description && (
                                <span className="text-red-500">
                                  Bu alan gereklidir
                                </span>
                              )}
                            </div>
                          </div>
                          <p className="break-words text-secondary text-lg mt-2">
                            İhale ücreti :{" "}
                            <span className="font-semibold">
                              ₺{charge ? (charge * 10) / 100 : 0}
                            </span>
                          </p>
                          <div className="lg:flex-row flex-col-reverse gap-y-2 lg:gap-y-0 flex items-center justify-between gap-x-3 mt-4">
                            {jobData?.hasBeenBookmarked?.bookmarked ? (
                              <div
                                className="cursor-pointer rounded-3xl  w-full py-3 border border-primary hover:bg-opacity-90 transition-all  text-primary text-center active:scale-95"
                                onClick={() =>
                                  handleDeleteBookmark(
                                    jobData?.hasBeenBookmarked?.bookmarkId
                                  )
                                }
                              >
                                <p className="break-words flex items-center justify-center gap-x-2">
                                  <MdBookmarkAdded className="text-primary text-xl cursor-pointer transition-all hover:opacity-90" />{" "}
                                  Favorilere eklendi
                                </p>
                              </div>
                            ) : (
                              <div
                                className="cursor-pointer rounded-3xl  w-full py-3 border border-primary hover:bg-opacity-90 transition-all  text-primary text-center active:scale-95"
                                onClick={() => handleAddBookmark(jobData?._id)}
                              >
                                <p className="break-words flex items-center justify-center gap-x-2">
                                  <FaRegBookmark className="text-primary text-xl cursor-pointer transition-all hover:opacity-90" />{" "}
                                  Yer imi
                                </p>
                              </div>
                            )}
                            <button
                              type="submit"
                              className="rounded-3xl  w-full py-3 bg-primary hover:bg-opacity-90 transition-all border text-white text-center active:scale-95"
                            >
                              Gönder
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </form>
                ) : null)}

              {jobData?.status !== "open" &&
              !(
                jobData?.status === "invited" &&
                jobData?.hired === userProfile?.user?._id
              ) ? (
                <p>Bu iş için ihale sona erdi.</p>
              ) : null}
            </div>

            <div className="border rounded-3xl max-w-[1400px]  lg:md:px-4     justify-center  p-6 mt-8  w-full">
              <div className="flex justify-between items-center mb-6 ">
                <p className="break-words text-xl lg:text-2xl font-medium">
                  Tüm Teklifler (
                  {
                    jobData?.proposals?.filter((proposal) => {
                      // Filter out deleted proposals
                      return proposal?.deleted === false;
                    }).length
                  }
                  )
                </p>

                <div className="flex flex-col mx-2">
                  {" "}
                  <label htmlFor="sort" className=" font-medium">
                    Sırala
                  </label>
                  <select
                    id="sort"
                    className="cursor-pointer px-3 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary lg:w-48"
                    value={sort}
                    onChange={(e) => setSort(e.target.value)}
                  >
                    <option value="Newest">En yeni</option>
                    <option value="Oldest">En eski</option>
                  </select>
                </div>
              </div>
              <div>
                {jobData?.proposals?.length > 0 ? (
                  <>
                    {/* Proposals from the user */}
                    {jobData?.proposals
                      ?.filter(
                        (item) =>
                          item?.created_by?._id === userProfile?.user?._id &&
                          item?.deleted === false
                      )
                      ?.map((item, index) => (
                        <div
                          key={index}
                          className={`p-3 transition-all  hover:rounded-xl border-b  ${
                            jobData?.proposals?.length - 1 === index &&
                            "border-none "
                          }`}
                        >
                          <div className="lg:hidden flex justify-end -mb-12 flex-col items-end">
                            <p className="break-words text-lg lg:text-2xl text-secondary  font-semibold mb-[2px]">
                              ₺{item?.bid_amount}
                            </p>
                            <p className="break-words font-medium text-secondary">
                              {item?.delivery_time} gün içinde
                            </p>
                          </div>
                          <div className="lg:flex space-y-2 lg:space-y-0 items-start justify-between mb-2">
                            <div
                              className="lg:flex items-center gap-x-2 cursor-pointer group"
                              onClick={() =>
                                window.open(
                                  `/profile/${item?.created_by?._id}`,
                                  "_blank"
                                )
                              }
                            >
                              {item?.created_by?.profile_picture ? (
                                <div className="w-[78px] h-[78px] border rounded-full  relative">
                                  <img
                                    src={item?.created_by?.profile_picture}
                                    width={78}
                                    height={78}
                                    alt="profile"
                                    className="object-cover rounded-full w-full h-full"
                                  />
                                </div>
                              ) : (
                                <div className="w-[78px] h-[78px] border bg-primary flex items-center justify-center rounded-full hover:cursor-pointer relative">
                                  <p className="break-words text-2xl text-white">
                                    {(item?.created_by?.first_name?.slice(
                                      0,
                                      1
                                    ) ?? "") +
                                      (item?.created_by?.lastName?.slice(
                                        0,
                                        1
                                      ) ?? "")}
                                  </p>
                                </div>
                              )}

                              <div className="">
                                <div className="flex items-center gap-x-2">
                                  <p className="break-words text-xl text-secondary group-hover:text-primary transition-all font-semibold">
                                    {item.created_by
                                      ? item?.created_by?.first_name +
                                        " " +
                                        item?.created_by?.lastName
                                      : "Kullanıcı Bulunamadı"}
                                  </p>
                                  {item?.accepted ? (
                                    <span className="rounded-3xl px-2 py-1  border-primary border text-primary bg-white text-center active:scale-95 transition-all hover:bg-opacity-90">
                                      Kabul edilmiş
                                    </span>
                                  ) : (
                                    <span className="rounded-3xl px-2 py-1  border-primary border text-primary bg-white text-center active:scale-95 transition-all hover:bg-opacity-90">
                                      Teklifin
                                    </span>
                                  )}
                                </div>
                                <div className="lg:flex items-center gap-x-2">
                                  <div className="text-secondary flex items-center gap-x-2 font-medium">
                                    <Rating
                                      style={{ maxWidth: 100 }}
                                      value={
                                        item?.created_by?.profile
                                          ?.overall_rating
                                      }
                                      readOnly
                                      itemStyles={{
                                        itemShapes: StickerStar,
                                        activeFillColor: "#35B900",
                                        inactiveFillColor: "#cecece",
                                      }}
                                    />

                                    <p className=" font-medium  text-secondary">
                                      {" "}
                                      {item?.created_by?.profile?.overall_rating?.toFixed(
                                        2
                                      )}
                                    </p>
                                    <p>
                                      (
                                      {
                                        item?.created_by?.profile
                                          ?.completed_projects
                                      }
                                      ){" "}
                                    </p>
                                  </div>
                                  <p className="break-words flex items-center ">
                                    <MdOutlineLocationOn className="w-6 h-6 mr-1 -ml-2 text-secondary" />
                                    <span className="text-lg text-secondary">
                                      {item?.created_by?.profile?.city},{" "}
                                      {item?.created_by?.profile?.country}
                                    </span>
                                  </p>
                                </div>
                                <p className="break-words text-secondary font-medium ">
                                  {item?.created_by?.profile?.category?.name} |{" "}
                                  {item?.created_by?.profile?.sub_title}
                                </p>
                              </div>
                            </div>
                            <div className="hidden lg:block">
                              <p className="break-words text-lg lg:text-2xl text-secondary  font-semibold mb-[2px]">
                                ₺{item?.bid_amount}
                              </p>
                              <p className="break-words font-medium text-secondary">
                                {item?.delivery_time} gün içinde
                              </p>
                            </div>
                          </div>
                          <p className="break-words text-secondary ">
                            <Description
                              description={
                                item?.proposal_description
                                  ? item?.proposal_description
                                  : ""
                              }
                              maxLines={1}
                              className={"text-secondary"}
                            />
                          </p>
                          <p className="break-words  text-secondary mt-2">
                            {" "}
                            {item?.createdAt &&
                              (({ timestamp }) => (
                                <span>
                                  {formatDistance(
                                    new Date(timestamp),
                                    new Date(),
                                    {
                                      addSuffix: true,
                                      locale: tr,
                                    }
                                  )}
                                </span>
                              ))({
                                timestamp: item?.createdAt,
                              })}
                          </p>
                          {jobData?.status === "open" && (
                            <div className="flex items-center my-4 lg:my-0 justify-end gap-x-2">
                              <button
                                className="rounded px-6 py-2 border-red-500 border text-red-500 text-center active:scale-95 transition-all hover:bg-opacity-90"
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent event from bubbling up
                                  setShowDeleteProposalModal(item?._id);
                                }}
                              >
                                Sil
                              </button>
                              <button
                                className="rounded px-6 py-2 border-primary border text-white bg-primary text-center active:scale-95 transition-all hover:bg-opacity-90"
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent event from bubbling up
                                  setProposalData({
                                    bid_amount: item?.bid_amount,
                                    delivery_time: item?.delivery_time,
                                    proposal_description:
                                      item?.proposal_description,
                                  });
                                  setShowEditProposalModal(item?._id);
                                }}
                              >
                                Düzenle
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                    {/* Other proposals */}
                    {jobData?.proposals
                      ?.filter(
                        (item) =>
                          item?.created_by?._id !== userProfile?.user?._id &&
                          item?.deleted === false
                      )
                      ?.map((item, index) => (
                        <div
                          key={index}
                          className={`p-3 transition-all   hover:rounded-xl border-b  ${
                            jobData?.proposals?.length - 1 === index &&
                            "border-none "
                          }`}
                        >
                          <div className="lg:hidden flex justify-end -mb-12 flex-col items-end">
                            <p className="break-words text-lg lg:text-2xl text-secondary  font-semibold mb-[2px]">
                              ₺{item?.bid_amount}
                            </p>
                            <p className="break-words font-medium text-secondary">
                              {item?.delivery_time} gün içinde
                            </p>
                          </div>
                          <div className="lg:flex space-y-2 lg:space-y-0 items-start justify-between mb-2">
                            <div
                              className="lg:flex items-center gap-x-2 cursor-pointer group"
                              onClick={() =>
                                window.open(
                                  `/profile/${item?.created_by?._id}`,
                                  "_blank"
                                )
                              }
                            >
                              {item?.created_by?.profile_picture ? (
                                <div className="w-[78px] h-[78px] border rounded-full  relative">
                                  <img
                                    src={item?.created_by?.profile_picture}
                                    width={78}
                                    height={78}
                                    alt="profile picture"
                                    className="object-cover rounded-full w-full h-full"
                                  />
                                </div>
                              ) : (
                                <div className="w-[78px] h-[78px] border bg-primary flex items-center justify-center rounded-full hover:cursor-pointer relative">
                                  <p className="break-words text-2xl text-white">
                                    {(item?.created_by?.first_name?.slice(
                                      0,
                                      1
                                    ) ?? "") +
                                      (item?.created_by?.lastName?.slice(
                                        0,
                                        1
                                      ) ?? "")}
                                  </p>
                                </div>
                              )}

                              <div className="">
                                <div className="flex items-center gap-x-2">
                                  <p className="break-words text-xl text-secondary group-hover:text-primary transition-all font-semibold">
                                    {item.created_by
                                      ? item?.created_by?.first_name +
                                        " " +
                                        item?.created_by?.lastName
                                      : "Kullanıcı Bulunamadı"}
                                  </p>
                                  {item?.accepted && (
                                    <span className="rounded-3xl px-2 py-1  border-primary border text-primary bg-white text-center active:scale-95 transition-all hover:bg-opacity-90">
                                      Kabul edilmiş
                                    </span>
                                  )}
                                </div>
                                <div className="lg:flex items-center gap-x-2">
                                  <div className=" text-secondary flex items-center gap-x-2 font-medium">
                                    <Rating
                                      style={{ maxWidth: 100 }}
                                      value={
                                        item?.created_by?.profile
                                          ?.overall_rating
                                      }
                                      readOnly
                                      itemStyles={{
                                        itemShapes: StickerStar,
                                        activeFillColor: "#35B900",
                                        inactiveFillColor: "#cecece",
                                      }}
                                    />

                                    <p className=" font-medium  text-secondary">
                                      {" "}
                                      {item?.created_by?.profile?.overall_rating?.toFixed(
                                        2
                                      )}
                                    </p>
                                    <p>
                                      (
                                      {
                                        item?.created_by?.profile
                                          ?.completed_projects
                                      }{" "}
                                      ){" "}
                                    </p>
                                  </div>
                                  <p className="break-words flex items-center ">
                                    <MdOutlineLocationOn className="w-6 h-6 mr-1 -ml-1 text-secondary" />
                                    <span className="text-lg text-secondary">
                                      {item?.created_by?.profile?.city},{" "}
                                      {item?.created_by?.profile?.country}
                                    </span>
                                  </p>
                                </div>
                                <p className="break-words text-secondary font-medium ">
                                  {item?.created_by?.profile?.category?.name} |{" "}
                                  {item?.created_by?.profile?.sub_title}
                                </p>
                              </div>
                            </div>
                            <div className="hidden lg:block">
                              <p className="break-words text-lg lg:text-2xl text-secondary  font-semibold mb-[2px]">
                                ₺{item?.bid_amount}
                              </p>
                              <p className="break-words font-medium text-secondary">
                                {item?.delivery_time} gün içinde
                              </p>
                            </div>
                          </div>
                          <p className="break-words text-secondary ">
                            <Description
                              description={
                                item?.proposal_description
                                  ? item?.proposal_description
                                  : ""
                              }
                              maxLines={1}
                              className={"text-secondary"}
                            />
                          </p>
                          <p className="break-words  text-secondary mt-2">
                            {" "}
                            {item?.createdAt &&
                              (({ timestamp }) => (
                                <span>
                                  {formatDistance(
                                    new Date(timestamp),
                                    new Date(),
                                    {
                                      addSuffix: true,
                                      locale: tr,
                                    }
                                  )}
                                </span>
                              ))({
                                timestamp: item?.createdAt,
                              })}
                          </p>
                          {jobData?.user === userProfile?.user?._id &&
                            (jobData?.status === "open" ||
                              jobData?.status === "invited") && (
                              <div className="flex flex-wrap lg:justify-end items-center gap-2 my-4 lg:my-0">
                                {jobData?.status === "invited" && (
                                  <button
                                    className="rounded px-6 py-2 border border-red-400 text-red-400 active:scale-95 transition-transform hover:bg-red-100"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setShowCancelJobModal(id);
                                    }}
                                  >
                                    Reddet
                                  </button>
                                )}

                                <button
                                  className="rounded px-6 py-2 border border-primary text-primary bg-white active:scale-95 transition-transform hover:bg-primary hover:text-white"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    sendMessageToFreelancer(
                                      item?.created_by?.email
                                    );
                                  }}
                                >
                                  Sohbeti Başlat
                                </button>

                                <button
                                  className="rounded px-6 py-2 border border-primary text-white bg-primary active:scale-95 transition-transform hover:bg-opacity-90"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowConfirmationModal(item?._id);
                                  }}
                                >
                                  Teklifi Kabul Et
                                </button>
                              </div>
                            )}
                        </div>
                      ))}
                  </>
                ) : (
                  <div className="flex items-center justify-center">
                    <img
                      src="/assets/404.png"
                      width={400}
                      height={400}
                      alt="Veri bulunamadı"
                    />
                  </div>
                )}

                <div
                  className={`lg:flex space-y-2 lg:space-y-0 items-center justify-center gap-x-2 `}
                >
                  {jobData?.proposals?.length > displayedRemainingReviews && (
                    <div className="flex items-center justify-center my-4">
                      <button
                        className="rounded-3xl px-3 py-1 border-primary border text-primary text-center active:scale-95"
                        onClick={loadMoreReviews}
                      >
                        Daha fazla yükle
                      </button>
                    </div>
                  )}
                  {displayedRemainingReviews > 10 && (
                    <div className="flex items-center justify-center my-4">
                      <button
                        className="rounded-3xl px-3 py-1 border-primary border text-primary text-center active:scale-95"
                        onClick={showLessReviews}
                      >
                        Daha az göster
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-1">
            <div className=" w-full  border rounded-3xl  bg-[#fff] overflow-hidden py-6 px-6">
              <p className="break-words text-lg  mb-6 font-semibold text-secondary">
                Müşteri Hakkında
              </p>
              <p className="break-words flex items-center mt-2">
                <MdOutlinePermIdentity className="w-6 h-6 mr-1 -ml-1 text-secondary" />
                <Link
                  className="text-lg !text-secondary hover:text-primary cursor-pointer"
                  to={`/profile/${jobData?.profile?.user?._id}`}
                  target="_blank"
                >
                  {jobData?.profile?.user?.first_name}{" "}
                  {jobData?.profile?.user?.lastName}
                </Link>
              </p>
              <div className=" text-secondary mt-2 flex items-center  ">
                <MdOutlineReviews className="w-6 h-6 mr-1 -ml-1 text-secondary" />
                <Rating
                  style={{ maxWidth: 100 }}
                  value={jobData?.profile?.client_overall_reviews}
                  readOnly
                  itemStyles={{
                    itemShapes: StickerStar,
                    activeFillColor: "#35B900",
                    inactiveFillColor: "#cecece",
                  }}
                />

                <p className=" font-medium mx-1  text-secondary">
                  {" "}
                  {jobData?.profile?.client_overall_reviews?.toFixed(2)}
                </p>
                <p>({jobData?.profile?.client_reviews?.length ?? 0})</p>
              </div>

              <p className="break-words flex items-center mt-2">
                <GrMapLocation className="w-6 h-6 mr-1 -ml-1 text-secondary" />
                <span className="text-lg text-secondary">
                  {jobData?.profile?.city}, {jobData?.profile?.country}
                </span>
              </p>

              <p className="break-words flex items-center mt-2">
                <MdJoinInner className="w-6 h-6 mr-1 -ml-1 text-secondary" />
                <span className="text-lg text-secondary">
                  Üyelik tarihi{" "}
                  {jobData &&
                    date &&
                    date != "Invalid Date" &&
                    format(date, "MMM dd, yyyy", { locale: tr })}
                </span>
              </p>
              <p className="break-words flex items-center mt-2">
                <FaHandHoldingDollar className="w-6 h-6 mr-1 -ml-1 text-secondary" />
                <span className="text-lg text-secondary">
                  ₺{jobData?.profile?.amount_spent} harcanan
                </span>
              </p>
              {jobData?.profile?.payment_verified ? (
                <div className="flex items-center mt-2 gap-x-1 text-secondary">
                  <RiVerifiedBadgeFill className="w-6 h-6 mr-1 -ml-1 text-secondary" />{" "}
                  <p className="break-words text-lg">Ödeme Doğrulandı</p>
                </div>
              ) : (
                <div className="flex items-center mt-2 gap-x-1 text-secondary">
                  <VscUnverified className="w-6 h-6 mr-1 -ml-1 text-secondary" />{" "}
                  <p className="break-words text-lg">Ödeme Doğrulanmamış</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <Footer />
      <AnimatePresence initial={false} onExitComplete={() => null}>
        {showEditJobModal && (
          <JobsModal
            setShowJobsModal={setShowEditJobModal}
            showJobsModal={showEditJobModal}
            userProfile={userProfile}
            isEdit={true}
            isDetail={true}
            initialData={{
              title: jobData?.title,
              budget: jobData?.budget,
              deadline: jobData?.deadline,
              project_size: jobData?.project_size,
              job_description: jobData?.job_description,
              skills: jobData?.skills,
              category: jobData?.category,
              subCategory: jobData?.subCategory,
              user: jobData?.profile?.user,
            }}
          />
        )}
        {showDeleteExperienceModal && (
          <DeleteModal
            loading={isLoading}
            onClose={() => setShowDeleteExperienceModal(null)}
            onConfirm={() => handleDeleteExperience()}
          />
        )}
        {showEditProposalModal && (
          <ProposalModal
            setShowEditProposalModal={setShowEditProposalModal}
            showEditProposalModal={showEditProposalModal}
            userProfile={userProfile}
            initialData={proposalData}
            jobId={id}
          />
        )}
        {showDeleteProposalModal && (
          <DeleteModal
            loading={isLoading}
            onClose={() => setShowDeleteProposalModal(null)}
            onConfirm={() => handleDeleteProposal()}
          />
        )}
        {showConfirmationModal && (
          <ConfirmationModal
            title="Teklifi kabul etmek"
            loading={isLoading}
            onClose={() => setShowConfirmationModal(null)}
            onConfirm={() => handleAcceptProposal(showConfirmationModal)}
          />
        )}
        {showCancelJobModal && (
          <DeleteModal
            loading={isLoading}
            onClose={() => setShowCancelJobModal(null)}
            onConfirm={() => handleCancelJob()}
          />
        )}
      </AnimatePresence>
    </ProtectedRoute>
  );
};

export default Job;
