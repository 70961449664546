import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { FaExclamationCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { GoPlus } from "react-icons/go";
import { IoIosArrowBack } from "react-icons/io";
import Cookies from "js-cookie";
import io from "socket.io-client";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { MdFileDownload } from "react-icons/md";
import { IoSend } from "react-icons/io5";
import { CiMenuKebab } from "react-icons/ci";

const ChatWindow = ({
  activeChat,
  setActiveChat,
  setOpenChatList,
  openChatList,
  setNewAction,
  newAction
}) => {
  const userProfile = useSelector((state) => state.user.data);
  const userId = userProfile?.user?._id;
  const [messages, setMessages] = useState([]);
  const [peer, setPeer] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [img, setImg] = useState(null);
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const modalRef = useRef(null);
  const socketRef = useRef(null);
  const chatEndRef = useRef(null);
  const [reportMenuOpen, setReportMenuOpen] = useState(null);

  useEffect(() => {
    const getPeerUser = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/profile/${activeChat}`
        );
        if (res.status === 200) {
          setPeer(res.data.data.user);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    const getMessages = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/message/${userId}/${activeChat}`
        );
        if (res.status === 200) {
          setMessages(res.data.data);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    getPeerUser();
    getMessages();
  }, [activeChat, userId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  useEffect(() => {
    if (!socketRef.current && userProfile?.user?._id) {
      socketRef.current = io(`https://api.kocfreelancing.com`, {
        query: { userId: userProfile.user._id },
      });

      socketRef.current.on("new_message", async (data) => {
        console.log("in");
        console.log(data);
        setMessages((prevMessages) => [...prevMessages, data]);
        setNewAction(true);
      });

      return () => {
        socketRef.current.disconnect();
        socketRef.current = null;
      };
    }
  }, [userProfile?.user?._id, newMessage]);

  const sendMessage = async () => {
    if (newMessage.trim() === "" && !img) return;
    if (file) return;

    const data =
      img && newMessage
        ? {
          sender: userId,
          receiver: activeChat,
          message: newMessage,
          image: img,
        }
        : img
          ? {
            sender: userId,
            receiver: activeChat,
            image: img,
          }
          : {
            sender: userId,
            receiver: activeChat,
            message: newMessage,
          };

    try {
      const postmessage = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/message/sendMessage`,
        data
      );

      if (postmessage.status === 201) {
        const newMsg = postmessage.data.data;
        socketRef.current.emit("new_message", newMsg);
        setMessages((prevMessages) => [...prevMessages, newMsg]);
        setNewMessage("");
        setImg(null);
        setSelectedFile(null);
        setNewAction(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileUpload = async (e) => {
    const data = e.target.files[0];
    const formdata = new FormData();
    formdata.append("file", data);
    console.log(data);
    console.log(formdata);
    if (data) {
      setFile(data);
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/message/sendMessageFile/${userId}/${activeChat}`,
          formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log(res);

        if (res.status === 200) {
          const newMsg = res.data.data;
          socketRef.current.emit("new_message", newMsg);
          setMessages((prevMessages) => [...prevMessages, newMsg]);
          setNewMessage("");
          setImg(null);
          setFile(null);
          setSelectedFile(null);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const saveImage = async () => {
    try {
      const formData = new FormData();
      formData.append("image", selectedFile);

      const postToImgBB = await axios.post(
        `https://api.imgbb.com/1/upload?key=3a1c90e62fd79d276ec757876690b3ef`,
        formData
      );
      if (postToImgBB.status === 200) {
        setImg(postToImgBB.data.data.url);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const reportMessage = async (messageId) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/message/report/${messageId}`
      );
      if (res.status === 200) {
        toast.success("Mesaj Bildirildi");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   if (selectedFile !== null) {
  //     saveImage();
  //   }
  // }, [selectedFile]);

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, [messages]);

  const downloadFile = (url, fileName) => {
    console.log(url);
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.error("Download failed:", error));
  };

  return (
    <div
      className={`w-full h-full lg:w-4/5 ${openChatList ? "hidden" : "block"}`}
    >
      <div className="flex-1 flex flex-col bg-[#fff] text-[#333] h-full">
        <div className="flex items-center gap-x-2 p-4 border-b border-gray-700">
          <button
            onClick={() => setOpenChatList(true)}
            className="lg:hidden block"
          >
            <IoIosArrowBack className="text-xl text-[#333]" />
          </button>
          <Link
            to={`/profile/${peer?._id}`}
            className="lg:text-xl text-lg font-bold cursor-pointer capitalize"
          >
            {peer?.first_name} {peer?.last_name}
          </Link>
        </div>
        <div className="flex-1 p-4 overflow-y-auto overflow-x-hidden mb-[.5rem] ">
          {loading ? (
            <div className="w-full h-[90vh] flex items-center justify-center">
              <div className="loader"></div>
            </div>
          ) : (
            <div>
                {messages.map((message, index) => (
                  <div
                    key={index}
                    className={`mb-4 flex ${message?.sender === userId ? "justify-end" : "justify-start"}`}
                  >
                    <div className="flex items-center gap-x-2">
                      <div
                        className={`p-4 rounded-lg text-[#fff] ${message?.sender === userId ? "bg-[#54BF2A]" : "bg-[#929395]"}`}
                      >
                        {message.image && (
                          <img
                            src={message.image}
                            alt="uploaded"
                            className="max-w-xs mb-2 rounded w-[150px] h-[150px]"
                          />
                        )}
                        {message.file && (
                          <div className="text-xs flex items-center gap-x-2">
                            <p className="">{message.file.file_name}</p>
                            <Link to={`${message.file.file_url}`} target="_blank" rel="noopener noreferrer">
                              <MdFileDownload className="text-xl" />{" "}
                            </Link>
                          </div>
                        )}
                        {message.message && <p>{message.message}</p>}
                        <span className="text-gray-800 text-xs">{message.time}</span>
                      </div>
                      <div className="relative">
                        <button
                          className="m-2 text-[#333] relative"
                          onClick={() => setReportMenuOpen(reportMenuOpen === message._id ? null : message._id)}
                        >
                          <CiMenuKebab />
                        </button>
                        {reportMenuOpen === message._id && (
                          <div className="bg-[#f8f8f8] absolute top-[110%] right-[20%] rounded-md p-2 text-[14px]">
                            <button
                              onClick={() => {
                                reportMessage(message._id);
                                setReportMenuOpen(null);
                              }}
                              className="text-[#35B900] text-nowrap"
                            >
                              Mesajı bildir
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}

              <div ref={chatEndRef} />
            </div>
          )}
        </div>
        {selectedFile && (
          <div className="p-4 border-t border-[#e1e0e0 ] hover:bg-[#e1e0e0 ] w-full">
            <img
              src={URL.createObjectURL(selectedFile)}
              alt="preview"
              className="w-20 h-20 object-cover mx-auto"
            />
          </div>
        )}
        {file && (
          <div className="p-4 border-t border-[#e1e0e0 ] hover:bg-[#f7f7f7] w-full">
            <p className="text-secondary text-center">{file.name}</p>
          </div>
        )}
        <div className="p-4 border-t border-[#e1e0e0 ] flex items-center gap-x-2 relative bottom-0 bg-[#f7f7f7]">
          {open && (
            <div
              ref={modalRef}
              className="flex flex-col items-start mr-4 absolute bg-[#f8f8f8] top-[-8rem] p-4 rounded-md"
            >
              <label
                htmlFor="file-upload"
                className="cursor-pointer hover:bg-[#e1e0e0 ] p-2 rounded-lg mb-2"
              >
                Dosya
              </label>
              <input
                id="file-upload"
                type="file"
                className="hidden"
                onChange={handleFileUpload}
              />
              <label
                htmlFor="image-upload"
                className="cursor-pointer hover:bg-[#e1e0e0 ] p-2 rounded-lg"
              >
                Görsel
              </label>
              <input
                id="image-upload"
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleFileUpload}
              />
            </div>
          )}
          <button
            onClick={() => setOpen(!open)}
            className="bg-[#35B900] p-2 rounded-full"
          >
            <GoPlus className="lg:text-2xl text-xl text-[#f5f5f5]" />
          </button>

          <input
            className="flex-1 p-2 rounded-lg bg-[#ddd] text-[#333] w-[50vw] lg:w-full border-[#e3e3e3] outline-none"
            type="text"
            placeholder="Buraya yaz.."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={(e) => {
              console.log(e.key);
              if (e.key === "Enter") sendMessage();
            }}
          />
          <button
            onClick={sendMessage}
            className="ml-4 p-2 rounded-lg bg-[#35B900] text-[#f5f5f5]"
          >
            <IoSend className="text-2xl" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatWindow;
