import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Navbar from "../../components/layouts/Navbar";
import Footer from "../../components/layouts/Footer";
import ProfileHeader from "../../components/profile/ProfileHeader";
import LeftSideBar from "../../components/profile/LeftSideBar";
import MainContent from "../../components/profile/MainContent";
import Experiences from "../../components/profile/Experiences";
import ProtectedRoute from "../../components/layouts/ProtectedRoute";
import ProfileCompletion from "../../components/profile/ProfileCompletion";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
// ErrorBoundary component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error("Error caught by ErrorBoundary: ", error, info);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong. Please try again later.</h1>;
    }
    return this.props.children;
  }
}

export default function Profile() {
  const userProfile = useSelector((state) => state?.user?.data);
  const isLoading = useSelector((state) => state?.user?.loading);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !userProfile) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userId");
      Cookies.remove("refreshToken");
      Cookies.remove("role");
      Cookies.remove("user_id");
      Cookies.remove("userSig");
      navigate("/auth/login");
    }
  }, [isLoading, navigate, userProfile]);

  return (
    <ProtectedRoute>
      <ErrorBoundary>
        <Navbar />
        {isLoading ? (
          <div className="border rounded-3xl max-w-[1400px] px-4 flex items-center justify-center h-[100vh] mt-28 mb-14 mx-auto">
            <div className="loader"></div>
          </div>
        ) : (
          <div className="max-w-[1400px] mt-28 mx-auto lg:px-4">
            <div className="border rounded-3xl !w-[95vw] max-w-[1400px] mt-28 mx-auto shadow">
              <ProfileCompletion userProfile={userProfile} />
              <ProfileHeader
                isMine={true}
                userProfile={userProfile}
                isLoading={isLoading}
              />
              <div className="lg:md:grid grid-cols-3">
                <LeftSideBar isMine={true} userProfile={userProfile} />
                <MainContent isMine={true} userProfile={userProfile} />
              </div>
            </div>
            <Experiences isMine={true} userProfile={userProfile} />
          </div>
        )}
        <Footer />
      </ErrorBoundary>
    </ProtectedRoute>
  );
}
