import React, { useState } from "react";
import Modal from "../ui/Modal";
import { RxCross1 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { editProposal, getJobById } from "../../axios/axios";
import { useForm } from "react-hook-form";
import { setLoading } from "../../redux/reducers/loadingSlice";
import toast from "react-hot-toast";

const ProposalModal = ({
  setShowEditProposalModal,
  showEditProposalModal,
  userProfile,
  initialData,
  jobId,
}) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initialData || {}, // Set default values from initialData if available
  });
  const loading = useSelector((state) => state.loading.loading);
  const [amount, setAmount] = useState(initialData.bid_amount);
  const onSubmit = (data) => {
    if ((amount * 10) / 100 > userProfile?.balance) {
      toast.error(
        "Hesabınızda yeterli bakiye olmadığı için teklifi gönderemezsiniz."
      );
      return;
    }
    dispatch(setLoading(true));
    dispatch(
      editProposal({
        dynamicParams: {
          proposalId: showEditProposalModal,
        },
        bodyData: data,
      })
    )
      .then(() =>
        dispatch(getJobById({ userId: userProfile?.user?._id, jobId: jobId }))
      )
      .then(() => {
        dispatch(setLoading(false));
        setShowEditProposalModal(null);
      })
      .catch((error) => {
        console.error("Error:", error);
        dispatch(setLoading(false));
      });
  };

  return (
    <Modal>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="lg:md:p-8 p-4 w-[90vw] rounded-2xl bg-white lg:md:min-w-[480px] md:max-w-lg "
      >
        <div className="flex flex-col max-h-[80vh] p-1 overflow-y-auto">
          <div className="flex items-center justify-between">
            <p className="break-words lg:md:text-3xl text-xl font-semibold">
              Teklifi düzenle
            </p>
            <RxCross1
              className="text-2xl cursor-pointer"
              onClick={() => setShowEditProposalModal(null)}
            />
          </div>
          <div>
            <div className="flex flex-wrap items-center justify-between gap-3">
              {/* Input fields for bid amount and Teslimat Süresi
               */}
              <div className="w-full">
                <label htmlFor="bid_amount" className="font-medium my-2">
                  Teklif Tutarı
                </label>
                <input
                  type="number"
                  id="bid_amount"
                  {...register("bid_amount", {
                    required: "Bu alan gereklidir",
                    min: {
                      value: 1,
                      message: "Teklif tutarı 1₺'den fazla olmalıdır",
                    },
                  })}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary mt-2"
                  placeholder="örn. 500"
                  onChange={(e) => setAmount(e.target.value)}
                  disabled
                />
                {errors.bid_amount && (
                  <span className="text-red-500">
                    {errors.bid_amount.message}
                  </span>
                )}
              </div>

              <div className="w-full">
                <label htmlFor="delivery_time" className="font-medium my-2">
                  Teslimat Süresi <span className="hidden md:block">(gün)</span>
                </label>
                <input
                  type="number"
                  id="delivery_time"
                  {...register("delivery_time", {
                    required: "Bu alan gereklidir",
                    min: {
                      value: 1,
                      message: "Teslimat Süresi 1 günden fazla olmalıdır",
                    },
                  })}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary mt-2"
                  placeholder="örn. 5"
                />
                {errors.delivery_time && (
                  <span className="text-red-500">
                    {errors.delivery_time.message}
                  </span>
                )}
              </div>
            </div>

            <div className="mt-2">
              <label
                htmlFor="proposal_description"
                className="font-medium text-sm"
              >
                Teklif Açıklaması
              </label>
              <textarea
                id="proposal_description"
                rows={6}
                {...register("proposal_description", {
                  required: true,
                })}
                className="w-full rounded-md border mt-1 border-gray-300 shadow-sm px-3 py-2 focus:outline-none focus:ring-primary focus:border-primary"
                placeholder="Sizi bu proje için en iyi aday yapan şey nedir?"
              />
              {errors.proposal_description && (
                <span className="text-red-500">Bu alan gereklidir</span>
              )}
            </div>
          </div>
        </div>
        <p className=" text-secondary text-lg">
          İhale ücreti :{" "}
          <span className="font-semibold">
            ₺{amount ? (amount * 10) / 100 : 0}
          </span>
        </p>
        <div className="flex items-center justify-between space-x-2 pt-3 border-t">
          <button
            type="button"
            className="px-4 py-3 font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none w-full"
            onClick={() => setShowEditProposalModal(null)}
          >
            İptal
          </button>
          <button
            type="submit"
            className={`px-4  font-medium text-white bg-primary border border-transparent rounded-md shadow-sm hover:bg-opacity-90 transition-all focus:outline-none w-full ${
              loading ||
              errors.delivery_time ||
              errors.proposal_description ||
              errors.bid_amount
                ? "cursor-not-allowed py-3 opacity-50"
                : "py-3"
            }`}
            disabled={
              loading ||
              errors.delivery_time ||
              errors.proposal_description ||
              errors.bid_amount
            }
          >
            {loading ? (
              <div className="loaderProfile mx-auto "></div>
            ) : (
              "Kaydet"
            )}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ProposalModal;
