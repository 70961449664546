import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../../components/layouts/Navbar";
import Footer from "../../../components/layouts/Footer";
import { useDispatch, useSelector } from "react-redux";
import Cookies from 'js-cookie';
import { setLoading } from "../../../redux/reducers/userSlice";
import toast from "react-hot-toast";
import axios from "axios";

// fswg
const Success = () => {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.user?.data);
  const amount = Cookies.get("amount");
  const userId = Cookies.get("userId");
  const onSendEmail = async () => {
    dispatch(setLoading(true));

    const currentBalance = parseFloat(userProfile?.balance) || 0; // Ensure Mevcut Bakiye is a number
    const amountToAdd = parseFloat(amount) || 0; // Ensure amount to add is a number

    // Gönder data to the server
    const dataToSend = {
      amount: amountToAdd,
      balance: currentBalance + amountToAdd,
      status: "approved",
      user: userId,
      type: "add",
      payment_system: "paytr",
    };

    try {
      const sendAddBalanceRequest = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/transaction/create`,
        dataToSend
      );

    } catch (error) {
      console.error("Error:", error);
      toast.error("Bakiye ekleme isteği gönderilemedi.");
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    onSendEmail();
  }, []);
  return (
    
    <div>
      <Navbar />
      <div className="border rounded-3xl max-w-[1400px]   mb-14 mt-28 mx-auto">
        <div className="w-full h-screen flex flex-col items-center justify-center">
          <div className="flex flex-col items-center justify-center">
            <p className="break-words text-3xl md:text-4xl lg:md:text-5xl text-gray-800 mt-12">
              Ödeme gerçekleştirilemedi
            </p>
            {/* <p className="break-words md:text-lg lg:md:text-xl text-gray-600 mt-8">
              Üzgünüm aradığınız sayfa bulunamadı
            </p> */}
            <Link
              to="/"
              className="flex items-center space-x-2 bg-primary hover:bg-opacity-90 text-gray-100 px-4 py-2 mt-12 rounded transition duration-150"
              title="Anasayfaya Dön"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span>Eve dön</span>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Success;
