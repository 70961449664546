import React, { useState, useEffect } from "react";
import axios from "axios";
import { CgSmileNone } from "react-icons/cg";
import { useSelector } from "react-redux";

const ChatList = ({
  activeChat,
  setActiveChat,
  setOpenChatList,
  openChatList,
  setNewAction,
  newAction,
}) => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const userProfile = useSelector((state) => state.user.data);
  const userId = userProfile?.user?._id;
  const [hasFetchedInitially, setHasFetchedInitially] = useState(false);

  useEffect(() => {
    const getContacts = async () => {
      if (!userId) return;

      if (!hasFetchedInitially || newAction) {
        setLoading(true);
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/profile/contact/${userId}`
          );

          if (res.status === 200) {
            const contactsWithUnreadCount = await Promise.all(
              res.data.data.map(async (contact) => {
                const unreadRes = await axios.get(
                  `${process.env.REACT_APP_BASE_URL}/message/${userId}`
                );

                const contactUnreadData = unreadRes.data.data.find(
                  (unreadItem) => unreadItem.sender._id === contact._id
                );

                return {
                  ...contact,
                  unreadMessages: contactUnreadData?.count || 0,
                };
              })
            );
            setContacts(contactsWithUnreadCount);
            setHasFetchedInitially(true);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
          if (newAction) setNewAction(false);
        }
      }
    };

    getContacts();
  }, [userId, newAction, hasFetchedInitially, setNewAction]);

  const handleChatClick = async (contactId) => {
    setActiveChat(contactId);
    setOpenChatList(false);

    // Mark messages as read when clicking on a chat
    try {
      await axios.patch(`${process.env.REACT_APP_BASE_URL}/message/markAsRead/${userId}/${contactId}`);

      // Set the unread count to 0 locally for the selected chat
      setContacts((prevContacts) =>
        prevContacts.map((contact) =>
          contact._id === contactId
            ? { ...contact, unreadMessages: 0 }
            : contact
        )
      );
    } catch (error) {
      console.error("Error marking messages as read:", error);
    }
  };

  useEffect(() => {
    if (activeChat) {
      // Mark messages as read immediately when chat becomes active
      const markMessagesAsRead = async () => {
        try {
          await axios.patch(`${process.env.REACT_APP_BASE_URL}/message/markAsRead/${userId}/${activeChat}`);

          // Update the unread count for the active chat to 0
          setContacts((prevContacts) =>
            prevContacts.map((contact) =>
              contact._id === activeChat ? { ...contact, unreadMessages: 0 } : contact
            )
          );
        } catch (error) {
          console.error("Error marking messages as read:", error);
        }
      };

      markMessagesAsRead();
    }
  }, [activeChat, userId]);

  return (
    <div
      className={`w-full lg:md:border-r lg:w-1/5 bg-[#f6f6f6] text-[#35B900] h-full p-4 lg:block ${openChatList ? "block" : "hidden"
        }`}
    >
      <div className="flex items-center justify-between pb-4 border-b border-gray-700">
        <h2 className="lg:text-xl text-lg font-bold">Kişiler Listesi</h2>
      </div>
      {loading ? (
        <div className="w-full h-[90vh] flex items-center justify-center">
          <div className="loader"></div>
        </div>
      ) : contacts.length === 0 ? (
        <div className="text-gray-800 flex justify-center w-full h-full items-center">
          Kişi bulunamadı{" "}
          <CgSmileNone className="lg:md:text-3xl text-2xl ps-2" />
        </div>
      ) : (
        contacts.map((contact, index) => (
          <div
            key={index}
            className={`hover:bg-[#ddd] p-4 border-b cursor-pointer ${activeChat === contact._id ? "bg-[#d6f5ca]" : ""
              }`}
            onClick={() => handleChatClick(contact._id)}
          >
            <div className="font-semibold lg:text-[16px] text-[16px] flex items-center gap-x-2">
              <div>
                {contact.profile_picture ? (
                  <img
                    src={contact.profile_picture}
                    alt="profile"
                    className="w-10 h-10 rounded-full"
                  />
                ) : (
                  <h2 className="w-10 h-10 rounded-full bg-[#35B900] text-[#fff] uppercase text-[18px] text-center flex items-center justify-center">
                    {contact.first_name[0] + contact.lastName[0]}
                  </h2>
                )}
              </div>
              <h2>
                {contact.first_name} {contact.lastName}
              </h2>
            </div>
            {contact.unreadMessages > 0 && (
              <div className="text-red-500 text-[12px] font-semibold text-end">
                {contact.unreadMessages} okunmamış mesaj
              </div>
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default ChatList;
