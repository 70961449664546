import React, { useState } from "react";
import Navbar from "../../components/layouts/Navbar";
import Footer from "../../components/layouts/Footer";
import { useDispatch, useSelector } from "react-redux";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import axios from "axios";
import toast from "react-hot-toast";
import ProtectedRoute from "../../components/layouts/ProtectedRoute";
import { setLoading } from "../../redux/reducers/loadingSlice";
import Select from "react-tailwindcss-select";
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

const Contact = () => {
  const loading = useSelector((state) => state.user?.loading);
  const userProfile = useSelector((state) => state.user?.data);
  const [amount, setAmount] = useState(null);
  const dispatch = useDispatch();
  const [paymentOption, setPaymentOption] = useState("card");
  const [bank, setBank] = useState(null);
  const [copyStatus, setCopyStatus] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [iframeToken, setIframeToken] = useState("");

  const handleCopy = (text, key) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopyStatus((prev) => ({ ...prev, [key]: true }));
        setTimeout(() => {
          setCopyStatus((prev) => ({ ...prev, [key]: false }));
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const banks = [
    { value: "Halkbank", label: "Halkbank" },
    { value: "VakifBank", label: "VakifBank" },
    { value: "Ziraat Bank", label: "Ziraat Bank" },
    { value: "Akbank", label: "Akbank" },
    { value: "Anadolubank", label: "Anadolubank" },
    { value: "Fibabanka", label: "Fibabanka" },
    { value: "Şekerbank", label: "Şekerbank" },
    { value: "Turkish Bank", label: "Turkish Bank" },
    { value: "Türk Ticaret Bankası", label: "Türk Ticaret Bankası" },
    { value: "Türkiye İş Bankası", label: "Türkiye İş Bankası" },
    { value: "Yapı Kredi", label: "Yapı Kredi" },
    { value: "Alternatif Bank", label: "Alternatif Bank" },
    { value: "Burgan Bank", label: "Burgan Bank" },
    { value: "DenizBank", label: "DenizBank" },
    { value: "Garanti BBVA", label: "Garanti BBVA" },
    { value: "HSBC", label: "HSBC" },
    { value: "ING", label: "ING" },
    { value: "Odeabank", label: "Odeabank" },
    { value: "QNB Finansbank", label: "QNB Finansbank" },
    { value: "TEB", label: "TEB" },
    {
      value: "Türkiye Emlak Katılım Bankası",
      label: "Türkiye Emlak Katılım Bankası",
    },
    { value: "Vakıf Katılım Bank", label: "Vakıf Katılım Bank" },
    { value: "Ziraat Katılım", label: "Ziraat Katılım" },
    { value: "Albaraka Türk", label: "Albaraka Türk" },
    { value: "Kuveyt Türk", label: "Kuveyt Türk" },
    {
      value: "Türkiye Finans Katılım Bankası",
      label: "Türkiye Finans Katılım Bankası",
    },
  ];

  const onSendEmail = async (e) => {
    e.preventDefault();
    if (paymentOption === "card") {
      return;
    }
    if (isSubmitting) {
      return;
    }

    if (amount < 1) {
      toast.error("Lütfen geçerli bir miktar girin");
      setAmount("");
      return;
    }

    dispatch(setLoading(true));
    setIsSubmitting(true);

    const currentBalance = parseFloat(userProfile?.balance) || 0; // Ensure Mevcut Bakiye is a number
    const amountToAdd = parseFloat(amount) || 0; // Ensure amount to add is a number

    // Gönder data to the server
    const dataToSend = {
      iban_number: userProfile?.payment?.iban_number,
      first_name: userProfile?.payment?.first_name,
      last_name: userProfile?.payment?.last_name,
      bank_name: userProfile?.payment?.bank_name,
      email: userProfile?.user?.email,
      amount: amountToAdd,
      balance: currentBalance + amountToAdd,
      status: "pending",
      user: userProfile?.user?._id,
      type: "add",
      payment_system: "IBAN",
    };

    try {
      const sendAddBalanceRequest = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/transaction/create`,
        dataToSend
      );

      if (sendAddBalanceRequest.status === 200) {
        toast.success("Bakiye ekleme isteği gönderildi");

        setAmount("");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Bakiye ekleme isteği gönderilemedi.");
    } finally {
      dispatch(setLoading(false));
      setIsSubmitting(false);
    }
  };

  const fetchUserIP = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      return response.data.ip;
    } catch (error) {
      console.error("Error fetching IP:", error);
      return "192.168.1.1";
    }
  };

  const fetchPaymentToken = async () => {
    const userIP = await fetchUserIP(); // Get the user's IP
    const data = {
      payment_amount: amount,
      user_id: userProfile.user._id,
      user_ip: userIP,
      merchant_oid: `order_${new Date().getTime()}`,
      email: userProfile.user.email,
      currency: "TL",
      first_name: userProfile?.payment?.first_name,
      last_name: userProfile?.payment?.last_name,
      bank_name: userProfile?.payment?.bank_name,
    };

    const setAmountToCookie = Cookies.set("amount", amount, {
      expires: 1
    })
    try {
      // First API call: Initiate payment
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/payment/initiate`,
        data
      );

      // Set iframe token for further usage
      setIframeToken(response.data.iframeToken);
     
    } catch (error) {
      // Error handling for both API calls
      console.error(
        "Error during payment process or transaction creation:",
        error
      );
      toast.error("İşlem tamamlanamadı.");
    }
  };

  return (
    <ProtectedRoute>
      <Navbar />
      <div className="max-w-[1400px] lg:px-4 my-28 mx-4 lg:md:mx-auto grid lg:md:md:grid-cols-2 grid-cols-1 gap-x-6 items-center">
        <div className="lg:md:md:block hidden">
          <Player
            autoplay
            loop
            src="https://lottie.host/97ed9d4e-3c50-40ca-8248-0da7d8b9bb96/s9rDi8rqTs.json"
            className="lg:md:md:h-[800px] h-[50vh]"
          >
            <Controls
              visible={false}
              buttons={["play", "repeat", "frame", "debug"]}
            />
          </Player>
        </div>
        <div className="w-full">
          <div className="border rounded-3xl w-full my-6 lg:md:md:p-6 p-2 ">
            <div className="flex items-center justify-center flex-col">
              <p className="break-words lg:md:md:text-3xl text-2xl font-medium text-center">
                Hesabınıza Bakiye Ekleyin
              </p>
              <p className="break-words text-secondary font-medium my-2 text-center">
                Mevcut Bakiye : ₺{userProfile?.balance?.toFixed(2) ?? 0}
              </p>
            </div>
            <div className="rounded-2xl bg-white border">
              <div className="border-b flex items-center justify-between rounded-2xl">
                <p
                  className={`p-5 w-1/2 border-r text-center cursor-pointer ${
                    paymentOption !== "card" && "bg-gray-100"
                  }`}
                  onClick={() => setPaymentOption("card")}
                >
                  Kredi Kartı / Banka Kartı ile Öde
                </p>
                <p
                  className={`p-5 w-1/2 text-center cursor-pointer ${
                    paymentOption !== "iban" && "bg-gray-100"
                  }`}
                  onClick={() => setPaymentOption("iban")}
                >
                  IBAN ile Öde
                </p>
              </div>
              {paymentOption === "card" ? (
                <div className="lg:md:p-8 p-4">
                  <p>Lütfen ödeme bilgilerinizi onaylayın:</p>
                  <div className="flex flex-col space-y-2">
                    <input
                      type="number"
                      placeholder="miktarı girin"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      className="p-3 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                    />
                    <button
                      onClick={fetchPaymentToken}
                      disabled={amount < 1 || !amount}
                      className="mt-4 bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded"
                    >
                      Kart ile Ödeme
                    </button>
                  </div>
                  {iframeToken && (
                    <>
                      <iframe
                        src={`https://www.paytr.com/odeme/guvenli/${iframeToken}`}
                        id="paytriframe"
                        scrolling="no"
                        style={{ width: "100%", height: "1050px" }}
                      ></iframe>
                      <script src="https://www.paytr.com/js/iframeResizer.min.js"></script>
                      <script>{`iFrameResize({},'#paytriframe');`}</script>
                    </>
                  )}
                </div>
              ) : (
                <form onSubmit={onSendEmail} className="lg:md:p-8 p-4">
                  <p className="font-semibold">
                    KocFreelancing Banka Bilgileri
                  </p>
                  <div className="lg:flex items-center my-2">
                    <p className="font-medium lg:text-nowrap">Hesap Adı :</p>
                    <div className="flex items-center justify-between w-full bg-gray-100 lg:ml-2 p-3 border rounded-md">
                      <p className="w-10/12">
                        {" "}
                        KOCFREELANCING BİLGİ TEKNOLOJİLERİ HİZMETLERİ TİCARET
                        LİMİTED ŞİRKETİ{" "}
                      </p>
                      <p
                        className="px-3 border-l cursor-pointer"
                        onClick={() =>
                          handleCopy(
                            "KOCFREELANCING BİLGİ TEKNOLOJİLERİ HİZMETLERİ TİCARET LİMİTED ŞİRKETİ",
                            "accountName"
                          )
                        }
                      >
                        {copyStatus.accountName ? "Kopyalandı" : " Kopyala"}
                      </p>
                    </div>
                  </div>
                  <div className="lg:flex items-center">
                    <p className="font-medium lg:text-nowrap">
                      IBAN Numarası :
                    </p>
                    <div className="flex items-center justify-between w-full bg-gray-100 lg:ml-2 p-3 border rounded-md">
                      <p className="w-10/12">
                        TR44 0020 5000 0994 4495 1000 01
                      </p>
                      <p
                        className="px-3 border-l cursor-pointer"
                        onClick={() =>
                          handleCopy("TR44 0020 5000 0994 4495 1000 01", "iban")
                        }
                      >
                        {copyStatus.iban ? "Kopyalandı" : " Kopyala"}
                      </p>
                    </div>
                  </div>
                  <p className="my-2 text-red-400">
                    Uyarı: Alıcı bilgilerini kontrol ederek, doğruluğundan emin
                    olunuz. Bankanızdan para transferi yaparken açıklama
                    bölümüne e-mail adresinizi doğru ve eksiksiz bir şekilde
                    yazınız ve doğruluğundan emin olunuz. Transfer işlemlerini
                    tamamladıktan sonra aşağıdaki bilgileri doldurunuz ve bakiye
                    talep et butonuna tıklayınız.
                  </p>
                  <div className="flex flex-col">
                    <div className="flex flex-col space-y-2 my-4">
                      <div className="flex items-center justify-between gap-2 w-full">
                        <div className="w-1/2">
                          <label htmlFor="sub_title" className=" font-medium">
                            Ad
                          </label>
                          <input
                            type="text"
                            id="sub_title"
                            name="sub_title"
                            className="w-full p-3 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                            placeholder=" Ad"
                            value={
                              userProfile
                                ? userProfile?.payment?.first_name
                                : ""
                            }
                            disabled={userProfile ? true : false}
                            required
                          />
                        </div>
                        <div className="w-1/2">
                          <label htmlFor="sub_title" className=" font-medium">
                            Soyad
                          </label>
                          <input
                            type="text"
                            id="sub_title"
                            name="sub_title"
                            className="w-full p-3 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                            placeholder=" Soyad"
                            value={
                              userProfile ? userProfile?.payment?.last_name : ""
                            }
                            disabled={userProfile ? true : false}
                            required
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-3 gap-2 w-full">
                        <label
                          htmlFor="phone"
                          className="col-span-full font-medium"
                        >
                          Telefon Numarası
                        </label>
                        <input
                          type="text"
                          id="phone"
                          name="phone"
                          className="col-span-full p-3 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                          placeholder=" Telefon Numarası"
                          value={
                            userProfile
                              ? userProfile?.user?.phone_number
                              : "Lütfen profilinizden Telefon Numaranızı ekleyin"
                          }
                          disabled={userProfile ? true : false}
                          required
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-2 w-full">
                        <label
                          htmlFor="email"
                          className="col-span-full font-medium"
                        >
                          E-mail Adresi
                        </label>
                        <input
                          type="text"
                          id="email"
                          name="email"
                          className="col-span-full p-3 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                          placeholder="E-mail Adresi"
                          value={
                            userProfile
                              ? userProfile?.user?.email
                              : "Lütfen profilinizden e-posta adresinizi ekleyin"
                          }
                          disabled={userProfile ? true : false}
                          required
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-2 w-full">
                        <label
                          htmlFor="iban"
                          className="col-span-full font-medium"
                        >
                          IBAN Numarası
                        </label>

                        <input
                          type="text"
                          id="iban"
                          name="iban"
                          className="col-span-full p-3 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                          placeholder="Lütfen profilden IBAN numaranız ekleyin"
                          value={userProfile?.payment?.iban_number}
                          disabled={userProfile ? true : false}
                          required
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-2 w-full">
                        <label
                          htmlFor="bank"
                          className="col-span-full font-medium"
                        >
                          Banka Adı
                        </label>
                        <input
                          type="text"
                          id="iban"
                          name="iban"
                          className="col-span-full p-3 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                          placeholder="Banka Seçiniz"
                          value={
                            userProfile
                              ? userProfile?.payment?.bank_name
                              : "Lütfen profilinizden bankanızı ekleyin"
                          }
                          disabled={userProfile ? true : false}
                          required
                        />
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-3 gap-2 w-full">
                        <label
                          htmlFor="amount"
                          className="col-span-full font-medium"
                        >
                          Tutar*
                        </label>
                        <input
                          type="number"
                          id="amount"
                          name="amount"
                          className="col-span-full p-3 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                          placeholder="Eklemek istediğiniz tutarı giriniz"
                          onChange={(e) =>
                            setAmount(parseFloat(e.target.value))
                          }
                          value={amount}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  {!userProfile?.payment?.first_name && (
                    <p className="text-red-400 mb-2">
                      Devam etmek için profil sayfasından ödeme ayrıntılarınızı
                      ekleyin
                    </p>
                  )}
                  <div className="flex items-center justify-between space-x-2 pt-3 border-t">
                    <button
                      type="submit"
                      className={`px-4 font-medium text-white bg-primary border border-transparent rounded-md shadow-sm hover:bg-opacity-90 transition-all focus:outline-none w-full ${
                        loading ? "cursor-not-allowed opacity-50" : "py-3"
                      }`}
                      disabled={
                        loading ||
                        amount < 1 ||
                        isSubmitting ||
                        !userProfile?.payment?.first_name
                      }
                    >
                      {loading ? (
                        <div className="loaderProfile mx-auto "></div>
                      ) : (
                        "Onayla"
                      )}
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </ProtectedRoute>
  );
};

export default Contact;
