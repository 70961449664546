import Footer from "../../components/layouts/Footer";
import Navbar from "../../components/layouts/Navbar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SlArrowLeftCircle } from "react-icons/sl";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { FaStar } from "react-icons/fa6";
import { MdOutlineLocationOn } from "react-icons/md";
import { VscUnverified } from "react-icons/vsc";
import { Rating, StickerStar } from "@smastrom/react-rating";
import {
  getCategoriesById,
  getCountries,
  getJobById,
  getSubCategoriesById,
} from "../../axios/axios";
import Description from "../../components/ui/Description";
import { setLoading } from "../../redux/reducers/loadingSlice";
import { AnimatePresence } from "framer-motion";
import JobsModal from "../../components/modals/JobsModal";
import Select from "react-tailwindcss-select";

import { formatDistance } from "date-fns";
import toast from "react-hot-toast";

const Category = () => {
  // Next.js router
  const navigate = useNavigate();

  const { id } = useParams();
  // Component state
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.user.data);
  const [searchTerm, setSearchTerm] = useState("");
  const loading = useSelector((state) => state.loading.loading);
  console.log(data);
  // Fetch profile data by ID
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Fetch categories data
        const categoriesResponse = await dispatch(
          getCategoriesById({
            id,
            search: searchTerm,
          })
        );
        setData(categoriesResponse?.payload?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [dispatch, id, searchTerm]);

  const [showHireNowModal, setShowHireNowModal] = useState(null);

  // Toggle show more İncelemeler
  const [displayedRemainingReviews, setDisplayedRemainingReviews] =
    useState(12);

  const loadMoreReviews = () => {
    setDisplayedRemainingReviews((prev) => prev + 12);
  };

  const showLessReviews = () => {
    setDisplayedRemainingReviews(12);
  };
  return (
    <div>
      <Navbar />
      <div className=" max-w-[1400px]    my-28 mx-0 lg:md:mx-auto  gap-x-6 lg:md:px-6 px-4 w-full">
        <div className="relative flex items-center w-full border h-12 rounded-3xl  bg-white overflow-hidden">
          <div className="grid place-items-center h-full w-12 text-gray-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>
          <input
            className="peer h-full w-full outline-none text-sm text-secondary pr-2"
            type="text"
            id="search"
            placeholder="Aradığınız Alt Kategoriler"
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
          />
        </div>
        <p className="break-words my-8 font-medium text-lg lg:text-start text-center lg:md:text-2xl ">
          Aradığınız Popüler Kategoriler ({data?.subCategories?.length})
        </p>

        <div className=" max-w-[1400px]  lg:px-4    gap-x-6 gap-y-24 mt-20 mb-14 mx-auto">
          {isLoading ? (
            <div className="flex items-center col-span-1 lg:md:col-span-3 justify-center h-[50vh]  mb-14 mx-auto">
              <div className="loader"></div>
            </div>
          ) : data?.subCategories?.length > 0 ? (
            [...data?.subCategories]
              .reverse()
              .slice(0, displayedRemainingReviews)
              .map((item, index) => (
                <div
                  className="relative flex justify-start my-8 rounded-xl bg-red-100 border shadow cursor-pointer group transition-all text-secondary "
                  key={index}
                  onClick={() => navigate(`/subcategory/${item?._id}`)}
                >
                  <div className="w-[30%] transition-all  px-6 rounded-xl">
                    {item?.image ? (
                      <img
                        // src={item?.image}
                        src={item?.image}
                        alt="picture"
                        className="rounded-xl w-60  transition-all -mt-4  !h-60 overflow-hidden h-full"
                        style={{
                          objectFit: "cover", // cover, contain, none
                        }}
                      />
                    ) : (
                      <p>Resim bulunamadı</p>
                    )}
                  </div>
                  <div className=" w-[70%] transition-all">
                    <div className="p-6 ">
                      <h5 className="mb-2 block font-sans text-xl font-semibold leading-snug tracking-normal text-blue-gray-900 antialiased">
                        {item?.name}
                      </h5>
                      <Description
                        description={item?.description}
                        maxLines={5}
                        maxWords={20}
                        className={"block font-sans text-base   "}
                      />
                    </div>

                    <div className="p-6 pt-0 mt-auto">
                      <button
                        className=" rounded-3xl  py-5 px-8   bg-primary hover:bg-opacity-90 transition-all border text-white text-center active:scale-95 "
                        onClick={() => navigate(`/subcategory/${item?._id}`)}
                      >
                        Ayrıntılara bakınız
                      </button>
                    </div>
                  </div>
                </div>
              ))
          ) : (
            <div className="flex items-center justify-center">
              <img
                src="/assets/404.png"
                width={400}
                height={400}
                alt="Veri bulunamadı"
              />
            </div>
          )}
          <div
            className={`flex items-center justify-center gap-x-2 lg:md:col-span-3 `}
          >
            {data?.subCategories?.length > displayedRemainingReviews && (
              <div className="flex items-center justify-center my-4">
                <button
                  className="rounded-3xl px-3 py-1 border-primary border text-primary text-center active:scale-95"
                  onClick={loadMoreReviews}
                >
                  Daha fazla yükle
                </button>
              </div>
            )}
            {displayedRemainingReviews > 12 && (
              <div className="flex items-center justify-center my-4">
                <button
                  className="rounded-3xl px-3 py-1 border-primary border text-primary text-center active:scale-95"
                  onClick={showLessReviews}
                >
                  Daha az göster
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Category;
