import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import NotFound from "./pages/404";
import "./App.css";
import Home from "./pages/home";
import Jobs from "./pages/jobs";
import JobDetails from "./pages/job/[id]";
import CategoryDetails from "./pages/category/[id]";
import SubCategoryDetails from "./pages/subcategory/[id]";
import PopularSubCategoryDetails from "./pages/popular/[id]";
import Categories from "./pages/categories";
import Contact from "./pages/contact";
import Help from "./pages/help";
import Protection from "./pages/protection";
import Myclients from "./pages/myclients";
import Myhires from "./pages/myhires";
import Success from "./pages/payment/success";
import Failed from "./pages/payment/failed";
import Terms from "./pages/terms";
import Signup from "./pages/auth/signup";
import Login from "./pages/auth/login";
import Verify from "./pages/auth/verify";
import Forget from "./pages/auth/forget";
import OtherProfile from "./pages/profile/[id]";
import MyProfile from "./pages/profile/me";
import Settings from "./pages/profile/settings";
import Transactions from "./pages/profile/transactions";
import Withdraw from "./pages/profile/withdraw";
import AddBalance from "./pages/profile/add-balance";
import Messages from "./pages/messages";
import Download from "./pages/download";
import "@smastrom/react-rating/style.css";
import { useDispatch, useSelector } from "react-redux";
import { setCompletionPercentage } from "./redux/reducers/userSlice";
import { Link } from "react-router-dom";
import { editInfo, getProfile } from "./axios/axios";
import { useMemo } from "react";

// Modal Component
const Modal = ({ show, onClose, message, handleAccept }) => {
  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-[5000000] ">
      <div className="bg-white p-6 rounded-lg shadow-lg text-center max-w-[70vw] md:max-w-[40vw] lg:max-w-[25vw]">
        <h2 className="text-xl font-semibold mb-4">{message}</h2>
        <p className="mb-4">
          Devam etmek için lütfen yeni politika güncellemelerimizi kabul edin.
        </p>
        <div className="flex justify-center gap-4 mb-4">
          <Link to="/terms" className="text-primary hover:underline">
            KVKK Aydınlatma Metni
          </Link>{" "}
          |
          <Link to="/protection" className="text-primary hover:underline">
            Kullanıcı Sözleşmesi
          </Link>
        </div>
        <button
          className="bg-primary text-white px-4 py-2 rounded transition"
          onClick={handleAccept}
        >
          Güncellemeleri Kabul Et
        </button>
      </div>
    </div>
  );
};

function App() {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.user.data);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  // Use useMemo to avoid recalculating weights on every render
  const weights = useMemo(
    () => ({
      education: 3,
      experience: 3,
      languages: 2,
      phone_verified: 2,
      projects: 4,
      skills: 4,
      sub_title: 2, // title is stored under sub_title
      hourly_rate: 2,
      description: 3,
      profile_picture: 1, // profile_picture is under user object
    }),
    []
  );

  // Calculate completion percentage based on fulfilled criteria
  const calculateCompletionPercentage = (profile, weights) => {
    let totalWeight = 0;
    let fulfilledWeight = 0;

    // Iterate through the profile fields and calculate fulfillment
    for (const criterion in weights) {
      if (
        ["languages", "education", "skills", "projects", "experience"].includes(
          criterion
        )
      ) {
        // For array fields, check if the field exists and has a length greater than zero
        if (profile[criterion] && profile[criterion].length > 0) {
          fulfilledWeight += weights[criterion];
        }
      } else if (criterion === "profile_picture") {
        // For profile picture, check inside the user object
        if (profile.user?.profile_picture) {
          fulfilledWeight += weights[criterion];
        }
      } else if (criterion === "sub_title") {
        // For title, check the sub_title field
        if (profile.sub_title) {
          fulfilledWeight += weights[criterion];
        }
      } else {
        // For other non-array fields, check if they exist and are not undefined
        if (profile[criterion] !== undefined) {
          fulfilledWeight += weights[criterion];
        }
      }
      totalWeight += weights[criterion];
    }

    // Calculate completion percentage
    const percentage = Math.round((fulfilledWeight / totalWeight) * 100);
    // Dispatch action to update completion percentage in the store
    dispatch(setCompletionPercentage(percentage));
    return percentage; // Return the percentage
  };

  useEffect(() => {
    // Calculate completion percentage when userProfile changes
    if (userProfile) {
      calculateCompletionPercentage(userProfile, weights);
    }
  }, [userProfile, weights, dispatch]); // Listen for changes in userProfile and weights

  // Scroll to top on route change and show modal if necessary
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);

    // Determine the message and modal visibility based on userProfile conditions
    if (userProfile) {
      const { kvkk, userAgreement } = userProfile;
      let message = "";

      if (!kvkk && !userAgreement) {
        message =
          "KVKK Aydınlatma Metni güncellendi & Kullanıcı Sözleşmesi güncellendi.";
      } else if (!kvkk) {
        message = "KVKK Aydınlatma Metni güncellendi";
      } else if (!userAgreement) {
        message = "Kullanıcı Sözleşmesi güncellendi.";
      }

      const shouldShowModal =
        message &&
        !["/terms", "/protection", "/404"].includes(location.pathname);

      setModalMessage(message);
      setModalVisible(shouldShowModal);
    }
  }, [location.pathname, userProfile]);

  const handleAccept = async () => {
    try {
      await dispatch(
        editInfo({
          dynamicParams: { userId: userProfile?.user?._id },
          bodyData: { userAgreement: true, kvkk: true },
        })
      );
      await dispatch(getProfile());
      setModalVisible(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="App">
      <Modal
        show={modalVisible}
        onClose={() => setModalVisible(false)}
        message={modalMessage}
        handleAccept={handleAccept}
      />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/job/:id" element={<JobDetails />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/category/:id" element={<CategoryDetails />} />
        <Route path="/subCategory/:id" element={<SubCategoryDetails />} />
        <Route path="/popular/:id" element={<PopularSubCategoryDetails />} />
        <Route path="/auth/signup" element={<Signup />} />
        <Route path="/auth/login" element={<Login />} />
        <Route path="/auth/verify" element={<Verify />} />
        <Route path="/auth/forget" element={<Forget />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/myclients" element={<Myclients />} />
        <Route path="/myhires" element={<Myhires />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/profile/me" element={<MyProfile />} />
        <Route path="/profile/:id" element={<OtherProfile />} />
        <Route path="/profile/settings" element={<Settings />} />
        <Route path="/profile/transactions" element={<Transactions />} />
        <Route path="/profile/withdraw" element={<Withdraw />} />
        <Route path="/profile/add-balance" element={<AddBalance />} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/help" element={<Help />} />
        <Route path="/payment/success" element={<Success />} />
        <Route path="/payment/failed" element={<Failed />} />
        <Route path="/protection" element={<Protection />} />
        <Route path="/download" element={<Download />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
