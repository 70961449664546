import { Link } from "react-router-dom";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTiktok,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";
import { CiMail, CiLocationOn } from "react-icons/ci";
import { FiPhone } from "react-icons/fi";
import { useEffect } from "react";
import { useState } from "react";

const Footer = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);
  return (
    <div className="z-10  !bg-[#f9f9f9] footer border-t">
      <footer className="relative max-w-[1400px] w-full mx-auto py-6 px-6 lg:px-4">
        <div className="container min-w-full">
          <div className="grid lg:grid-cols-3 grid-cols-1 gap-y-6 justify-between">
            <LinkGroup header="BİLGİ">
              <NavLink link="/help" label="Yardım & Destek" />
              <NavLink link="/protection" label="Kullanıcı Sözleşmesi" />
              <NavLink link="/terms" label="KVKK Aydınlatma Metni" />
              <NavLink link="/download" label="Uygulamamızı İndirin " />
            </LinkGroup>
            <LinkGroup
              header="POPÜLER KATEGORİLER"
              classNames="lg:!text-center"
            >
              <NavLink
                link="/category/6691409a87f6eab8d90bea5f"
                label="Dijital Hizmetler"
              />
              <NavLink
                link="/category/669143bd87f6eab8d90bea94"
                label="Grafik Tasarım"
              />
              <NavLink
                link="/category/6691450687f6eab8d90beaac"
                label="Dijital Pazarlama"
              />
              <NavLink
                link="/category/669145d9a845c6bf53dcc2e9"
                label="Video ve Animasyon"
              />
              <NavLink
                link="/category/669146ad87f6eab8d90beb2f"
                label="Temizlik"
              />
            </LinkGroup>
            <LinkGroup header="DESTEK" classNames="lg:!text-center">
              <NavLink
                link="mailto:destek@kocfreelancing.com"
                label="destek@kocfreelancing.com"
              />
              {isAuthenticated && (
                <NavLink
                  link="https://wa.me/905354241801"
                  label="WhatsApp Destek"
                />
              )}
            </LinkGroup>
          </div>
        </div>
        <div className="border-t w-full py-6 grid lg:grid-cols-3 grid-cols-1 justify-items-center lg:justify-items-start lg:items-center items-start">
          <p className="lg:hidden flex items-center justify-center  text-3xl leading-loose text-gray-400 font-bold ftFont text-start w-full mb-2">
            <Link to="/">
              <img src="/logo.png" width={200} height={10} alt="Logo" />
            </Link>
          </p>
          <p className=" text-base leading-loose text-gray-600 lg:text-start text-center w-full">
            © 2024 Tüm Hakları Saklıdır.
          </p>
          <div className="hidden lg:flex flex-col items-center justify-center  text-3xl leading-loose text-gray-400 font-bold ftFont text-start w-full ">
            <Link to="/">
              <img src="/logo.png" width={200} height={10} alt="Logo" />
            </Link>
          </div>

          <div className="flex items-center gap-x-4 w-full lg:!justify-end justify-center lg:mt-0 mt-4">
            <a href="https://www.instagram.com/kocfreelancing/" target="_blank">
              <FaInstagram className="text-xl text-gray-500 hover:text-primary transition-all duration-500" />
            </a>
            <a href="https://www.tiktok.com/@kocfreelancing" target="_blank">
              <FaTiktok className="text-xl text-gray-500 hover:text-primary transition-all duration-500" />
            </a>
            <a href="https://x.com/kocfreelancing" target="_blank">
              <FaXTwitter className="text-xl text-gray-500 hover:text-primary transition-all duration-500" />
            </a>
            <a href="https://www.facebook.com/" target="_blank">
              <FaFacebook className="text-xl text-gray-500 hover:text-primary transition-all duration-500" />
            </a>
            <a href="https://www.youtube.com/@KocFreelancing" target="_blank">
              <FaYoutube className="text-xl text-gray-500 hover:text-primary transition-all duration-500" />
            </a>
            <a
              href="https://www.linkedin.com/company/kocfreelancing/"
              target="_blank"
            >
              <FaLinkedin className="text-xl text-gray-500 hover:text-primary transition-all duration-500" />
            </a>
            <a href="mailto:kf@kocfreelancing.com" target="_blank">
              <CiMail className="text-xl text-gray-500 hover:text-primary transition-all duration-500" />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;

const LinkGroup = ({ children, header, classNames }) => {
  return (
    <div
      className={`${classNames} w-full px-4 text-center lg:text-start mx-auto lg:mx-0`}
    >
      <div className="mb-6 lg:mb-10 w-full">
        <h4 className="mb-10 text-2xl whitespace-nowrap font-semibold text-primary">
          {header}
        </h4>
        <ul className="space-y-3">{children}</ul>
      </div>
    </div>
  );
};

const NavLink = ({ link, label }) => {
  return (
    <li>
      <Link
        to={link}
        className="inline-block text-base leading-loose text-[#404145] hover:text-[rgba(226,55,98,48%)] whitespace-nowrap transition-all duration-500"
      >
        {label}
      </Link>
    </li>
  );
};
