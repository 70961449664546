import Footer from "../../components/layouts/Footer";
import Navbar from "../../components/layouts/Navbar";
import Description from "../../components/ui/Description";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCategories } from "../../axios/axios";
import { useNavigate } from "react-router-dom";

const Categories = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Local state to store jobs and loading
  const [categories, setCategories] = useState([]);
  const [categoryLoading, setCategoryLoading] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");

  // Dispatch the getCategories action on component mount
  useEffect(() => {
    // Update local loading state to true when fetching categories
    setCategoryLoading(true);
    dispatch(getCategories(searchTerm))
      .then((response) => {
        // Update local jobs state with fetched data
        setCategories(response.payload?.data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      })
      .finally(() => {
        // Update local loading state to false after fetching completes
        setCategoryLoading(false);
      });
  }, [dispatch, searchTerm]);

  // Toggle show more İncelemeler
  const [displayedRemainingReviews, setDisplayedRemainingReviews] =
    useState(12);

  const loadMoreReviews = () => {
    setDisplayedRemainingReviews((prev) => prev + 12);
  };

  const showLessReviews = () => {
    setDisplayedRemainingReviews(12);
  };

  // State to track hover on category
  const [hoveredCategory, setHoveredCategory] = useState(null);

  return (
    <div>
      <Navbar />
      <div className=" max-w-[1400px]    my-28 mx-0 lg:md:mx-auto  gap-x-6 lg:md:px-6 px-4 w-full">
        <div className="relative flex items-center w-full border h-12 rounded-3xl  bg-white overflow-hidden">
          <div className="grid place-items-center h-full w-12 text-gray-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>
          <input
            className="peer h-full w-full outline-none text-sm text-secondary pr-2"
            type="text"
            id="search"
            placeholder="Kategori Ara"
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
          />
        </div>
        <p className="break-words my-12 font-medium lg:text-start text-center text-xl lg:md:text-3xl ">
          Aradığınız Kategoriler ({categories?.length ? categories?.length : 0})
        </p>

        <div className=" max-w-[1400px]  lg:px-4  place-items-center  grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-x-6 gap-y-6 md:gap-y-24 mt-20 mb-14 mx-auto">
          {categoryLoading ? (
            <div className="flex items-center col-span-1 md:col-span-2 lg:col-span-3 justify-center h-[50vh]  mb-14 mx-auto">
              <div className="loader"></div>
            </div>
          ) : categories?.length > 0 ? (
            [...categories]
              .sort((a, b) => (b.freelancers ?? 0) - (a.freelancers ?? 0))
              .slice(0, displayedRemainingReviews)
              .map((item, index) => (
                <div
                  className="flip-container  shadow-lg md:w-[300px]  md:h-[300px] w-[250px] h-[250px] flex flex-col justify-center relative cursor-pointer"
                  key={index}
                  style={{ border: "2px solid #a9cfa9" }}
                  onClick={() => navigate(`/category/${item?._id}`)}
                  onMouseEnter={() => setHoveredCategory(index)}
                  onMouseLeave={() => setHoveredCategory(null)}
                  onTouchStart={() => setHoveredCategory(index)}
                  onTouchEnd={() => setHoveredCategory(null)}
                >
                  <div className="flipper flex flex-col items-center justify-center">
                    <div className="front relative flex flex-col rounded-xl bg-white  shadow cursor-pointer group transition-all text-secondary">
                      <div className="h-full  flex items-center justify-center">
                        <h5 className=" flex flex-col items-center justify-center font-sans lg:text-xl text-lg font-semiboldtext-blue-gray-900 antialiased">
                          {item?.name}
                          <p className="break-words font-medium lg:text-md text-sm">
                            Toplam Serbest Çalışan : {item?.freelancers ?? 0}
                          </p>
                        </h5>
                      </div>
                    </div>
                    <div className="back w-full transition-all  absolute top-[-6.3rem] md:top-[-9.3rem]">
                      {item?.image && (
                        <img
                          src={item?.image}
                          alt="profile"
                          className={`w-full transition-all md:h-[300px] h-[200px] overflow-hidden ${
                            hoveredCategory === index
                              ? "opacity-100"
                              : "opacity-0"
                          }`}
                          style={{
                            objectFit: "cover",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))
          ) : (
            <div className="flex items-center justify-center">
              <img
                src="/assets/404.png"
                width={400}
                height={400}
                alt="Veri bulunamadı"
              />
            </div>
          )}
          <div
            className={`flex items-center justify-center gap-x-2 lg:md:col-span-3 `}
          >
            {categories?.length > displayedRemainingReviews && (
              <div className="flex items-center justify-center my-4">
                <button
                  className="rounded-3xl px-3 py-1 border-primary border text-primary text-center active:scale-95"
                  onClick={loadMoreReviews}
                >
                  Daha fazla yükle
                </button>
              </div>
            )}
            {displayedRemainingReviews > 12 && (
              <div className="flex items-center justify-center my-4">
                <button
                  className="rounded-3xl px-3 py-1 border-primary border text-primary text-center active:scale-95"
                  onClick={showLessReviews}
                >
                  Daha az göster
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Categories;
